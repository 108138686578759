import { Pipe, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchCriteriaModel, KeyValuePairModel, LimitedResult } from 'src/app/models';
import { CorporateEntitySearchResultModel, CorporateEntityModel } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CorporateEntityService {
  ceEndpoint = environment.masterDataApiRoot + '/corporateentities';

  constructor(private http: HttpClient) {
  }

  // For populating SelectList
  public getCorporateEntityList(): Observable<KeyValuePairModel[]> {
    const searchCriteria: SearchCriteriaModel = new SearchCriteriaModel();
    searchCriteria.criteria = '';
    const limit = 9999;

    return this.http.post<CorporateEntitySearchResultModel[]>(`${this.ceEndpoint}/search/?limit=${limit}`, searchCriteria)
      .pipe(
        map(res => res['Items'].map(item => new KeyValuePairModel(item.CorporateEntityIdentifier.toString(),
          `${item.CorporateEntityIdentifier} :: ${item.CorporateEntityName} :: ${item.CorporateEntityNameAnonymous}`))));
  }

  public search(searchCriteria: SearchCriteriaModel,
    limit: number,
    offset: number,
    sort: string): Observable<LimitedResult<CorporateEntitySearchResultModel>> {
    const url = `${this.ceEndpoint}/search?limit=${limit}&offset=${offset}&sort=${sort}`;
    return this.http.post<LimitedResult<CorporateEntitySearchResultModel>>(url, searchCriteria);
  }

  public get(corporateEntityIdentifier: number): Observable<CorporateEntityModel> {
    const url = `${this.ceEndpoint}/${corporateEntityIdentifier}`;
    return this.http.get<CorporateEntityModel>(url);
  }

  public save(insert: boolean, corporateEntityModel: CorporateEntityModel): Observable<CorporateEntityModel> {
    if (insert) {
      return this.post(corporateEntityModel);
    } else {
      return this.put(corporateEntityModel);
    }
  }

  private post(corporateEntityModel: CorporateEntityModel): Observable<CorporateEntityModel> {
    return this.http.post<CorporateEntityModel>(this.ceEndpoint, corporateEntityModel);
  }

  private put(corporateEntityModel: CorporateEntityModel): Observable<CorporateEntityModel> {
    const url = `${this.ceEndpoint}/${corporateEntityModel.CorporateEntityIdentifier}`;
    return this.http.put<CorporateEntityModel>(url, corporateEntityModel);
  }

  public delete(id: number): Observable<any> {
    const url = `${this.ceEndpoint}/${id}`;
    return this.http.delete(url);
  }
}
