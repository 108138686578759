<h2>{{title}}</h2>
<mat-dialog-content>
   <p>{{body}}</p>
</mat-dialog-content>
<mat-dialog-actions  align="end">
    <button mat-button color="primary" (click)="confirm()" >
        Yes
    </button>
    <button mat-button color="primary" (click)="dismiss()">
        Cancel
    </button>
</mat-dialog-actions>