export const environment = {
    production: false,
    envName: 'dev',

    applicationName: 'Inmar.Healthcare.MasterData',
    applicationDisplayName: 'MasterData',
    rootUrl: 'https://dev-web-masterData.healthcare.inmar.com',
    authZApiRoot: 'https://dev-api-authz.healthcare.inmar.com/v1.0',
    healthcareTopBar: 'https://dev-web-auth.healthcare.inmar.com/Scripts/healthcare-topbar.js',
    healthcareSignInAddress: 'https://dev-web-auth.healthcare.inmar.com/SignIn/SignIn',

    masterDataApiRoot: 'https://dev-api-masterData.healthcare.inmar.com/v1.0/api',
    defaultListLimit: 1000,   // for drop down lists, we want to get *all items so let's try this number
    appInsights: {
      instrumentationKey: '',
    },
    PrismEnvironmentAllowedValues: {
      PrismLPARs: [
        "PRISMDEV"
      ],
      SpectrumSQLInstances: [
        "NWSWSQLHC001.CLIENT.EXT,64001"
      ]
    }
  };
