<div class="inm-layout-main__content">
  <h3 class="inm-headline--page-title">Pharmacies</h3>
  <section class='inm-wrapper'>

    <form [formGroup]="searchFormGroup">

      <div class="inm-spaced">
        <mat-form-field>
          <input matInput placeholder="Search criteria" formControlName="searchCriteria">
        </mat-form-field>
        <section class='inm-spaced--compact'>
          <button class="inm-button--action" (click)="search()" [disabled]="searchFormGroup.invalid">Search</button>
          <button class="inm-button" (click)="reset()">Reset</button>
        </section>
        <section class='inm-spaced--compact' *ngIf="hasAddPharmacyFeature()">
          <button class="inm-button" (click)="add()">Add</button>
          <button class="inm-button" (click)="upload()">Upload</button>
        </section>
      </div>
    </form>

    <div class="inm-spaced" [hidden]="!loadingGrid">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="inm-spaced" [hidden]="loadingGrid || !dataSource">
      <div class="table-container" style="overflow-x:auto;">
        <table mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="ActionEdit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="actionColumn"> Edit </th>
            <td mat-cell *matCellDef="let element" class="actionColumn">
              <button mat-button (click)="edit(element)" style="cursor:pointer">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="ActionDelete">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="actionColumn"> Delete </th>
            <td mat-cell *matCellDef="let element" class="actionColumn">
              <button mat-button style="cursor:pointer" (click)="openDeleteDialog(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="PharmacyId">
            <th mat-header-cell *matHeaderCellDef class="intColumn" mat-sort-header arrowPosition='before'> PharmacyId
            </th>
            <td mat-cell *matCellDef="let element" class="intColumn" nowrap> {{element.PharmacyId}} </td>
          </ng-container>
          <ng-container matColumnDef="QualifiedProviderId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> QualifiedProviderId </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.QualifiedProviderId}} </td>
          </ng-container>
          <ng-container matColumnDef="QualifiedProviderIdQualifier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> QualifiedProviderIdQualifier </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.QualifiedProviderIdQualifier}} </td>
          </ng-container>
          <ng-container matColumnDef="EffectiveDateBegin">
            <th mat-header-cell *matHeaderCellDef class="dateColumn" mat-sort-header arrowPosition='before'>
              EffectiveDateBegin </th>
            <td mat-cell *matCellDef="let element" class="dateColumn" nowrap>
              {{element.EffectiveDateBegin | date:'MM/dd/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="EffectiveDateEnd">
            <th mat-header-cell *matHeaderCellDef class="dateColumn" mat-sort-header arrowPosition='before'>
              EffectiveDateEnd </th>
            <td mat-cell *matCellDef="let element" class="dateColumn" nowrap>
              {{element.EffectiveDateEnd | date:'MM/dd/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="NameOrDescription">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NameOrDescription </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.NameOrDescription}} </td>
          </ng-container>
          <ng-container matColumnDef="NationalProviderIdentifier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NationalProviderIdentifier </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.NationalProviderIdentifier}} </td>
          </ng-container>
          <ng-container matColumnDef="NcpdpChainCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NcpdpChainCode </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.NcpdpChainCode}} </td>
          </ng-container>
          <ng-container matColumnDef="NcpdpProviderId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> NcpdpProviderId </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.NcpdpProviderId}} </td>
          </ng-container>
          <ng-container matColumnDef="AddressStreet1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AddressStreet1 </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.AddressStreet1}} </td>
          </ng-container>
          <ng-container matColumnDef="AddressStreet2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AddressStreet2 </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.AddressStreet2}} </td>
          </ng-container>
          <ng-container matColumnDef="AddressCity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AddressCity </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.AddressCity}} </td>
          </ng-container>
          <ng-container matColumnDef="AddressStateCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AddressStateCode </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.AddressStateCode}} </td>
          </ng-container>
          <ng-container matColumnDef="AddressPostalCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> AddressPostalCode </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.AddressPostalCode}} </td>
          </ng-container>
          <ng-container matColumnDef="PhoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PhoneNumber </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.PhoneNumber}} </td>
          </ng-container>
          <ng-container matColumnDef="DaysOfOperationSundayFlag">
            <th mat-header-cell *matHeaderCellDef class="boolColumn" mat-sort-header> DaysOfOperationSundayFlag </th>
            <td mat-cell *matCellDef="let element" nowrap class="boolColumn">
              <mat-checkbox [checked]="element.DaysOfOperationSundayFlag" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="DaysOfOperationMondayFlag">
            <th mat-header-cell *matHeaderCellDef class="boolColumn" mat-sort-header> DaysOfOperationMondayFlag </th>
            <td mat-cell *matCellDef="let element" nowrap class="boolColumn">
              <mat-checkbox [checked]="element.DaysOfOperationMondayFlag" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="DaysOfOperationTuesdayFlag">
            <th mat-header-cell *matHeaderCellDef class="boolColumn" mat-sort-header> DaysOfOperationTuesdayFlag </th>
            <td mat-cell *matCellDef="let element" nowrap class="boolColumn">
              <mat-checkbox [checked]="element.DaysOfOperationTuesdayFlag" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="DaysOfOperationWednesdayFlag">
            <th mat-header-cell *matHeaderCellDef class="boolColumn" mat-sort-header> DaysOfOperationWednesdayFlag </th>
            <td mat-cell *matCellDef="let element" nowrap class="boolColumn">
              <mat-checkbox [checked]="element.DaysOfOperationWednesdayFlag" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="DaysOfOperationThursdayFlag">
            <th mat-header-cell *matHeaderCellDef class="boolColumn" mat-sort-header> DaysOfOperationThursdayFlag </th>
            <td mat-cell *matCellDef="let element" nowrap class="boolColumn">
              <mat-checkbox [checked]="element.DaysOfOperationThursdayFlag" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="DaysOfOperationFridayFlag">
            <th mat-header-cell *matHeaderCellDef class="boolColumn" mat-sort-header> DaysOfOperationFridayFlag </th>
            <td mat-cell *matCellDef="let element" nowrap class="boolColumn">
              <mat-checkbox [checked]="element.DaysOfOperationFridayFlag" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="DaysOfOperationSaturdayFlag">
            <th mat-header-cell *matHeaderCellDef class="boolColumn" mat-sort-header> DaysOfOperationSaturdayFlag </th>
            <td mat-cell *matCellDef="let element" nowrap class="boolColumn">
              <mat-checkbox [checked]="element.DaysOfOperationSaturdayFlag" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="CorporateEntityIdentifier">
            <th mat-header-cell *matHeaderCellDef class="intColumn" mat-sort-header arrowPosition='before'>
              CorporateEntityIdentifier </th>
            <td mat-cell *matCellDef="let element" class="intColumn" nowrap> {{element.CorporateEntityIdentifier}} </td>
          </ng-container>
          <ng-container matColumnDef="CorporateGroupOrDivision">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CorporateGroupOrDivision </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.CorporateGroupOrDivision}} </td>
          </ng-container>
          <ng-container matColumnDef="CorporateLocationNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CorporateLocationNumber </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.CorporateLocationNumber}} </td>
          </ng-container>
          <ng-container matColumnDef="DataUsageCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> DataUsageCode </th>
            <td mat-cell *matCellDef="let element" nowrap> {{(element.DataUsageCode === 'G') 
              ? 'Green' 
              : (element.DataUsageCode === 'R') 
                ? 'Red' : element.DataUsageCode}} </td>
          </ng-container>
          <ng-container matColumnDef="DemoFlag">
            <th mat-header-cell *matHeaderCellDef class="boolColumn" mat-sort-header> DemoFlag </th>
            <td mat-cell *matCellDef="let element" nowrap class="boolColumn">
              <mat-checkbox [checked]="element.DemoFlag" [disabled]="true"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="MarketCategory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> MarketCategory </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.MarketCategory}} </td>
          </ng-container>
          <ng-container matColumnDef="PeerGroup">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PeerGroup </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.PeerGroup}} </td>
          </ng-container>
          <ng-container matColumnDef="PharmacyType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PharmacyType </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.PharmacyType}} </td>
          </ng-container>
          <ng-container matColumnDef="RetailBanner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> RetailBanner </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.RetailBanner}} </td>
          </ng-container>
          <ng-container matColumnDef="HiveClientId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> HiveClientId </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.HiveClientId}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[30,60,90,10]" showFirstLastButtons></mat-paginator>
      </div>

    </div>

  </section>
</div>
<!-- <div class="inm-spaced" [hidden]="!showFileDialog">
<app-pharmacy-upload-dialog id="associateDocumentsDialog"
  [(show)]="showFileDialog" 
  (uploadComplete)="uploadComplete($event)">
</app-pharmacy-upload-dialog>
</div> -->