import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { AuthorizationService } from '../services/authorization.service';
import { environment } from '../../environments/environment';

@Injectable()
export class FeatureGuard implements CanActivate {
    private topBarLoaded = false;
    constructor(private router: Router, private authorizationService: AuthorizationService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            if (!this.authorizationService.topBarLoaded) {
                resolve(false);
                return;
            }

            let found = false;
            const featureName = route.data.feature;

            found = _.find(this.authorizationService.HealthcareTopBar.currentUserDetails.Features,
                { Name: featureName, App: environment.applicationName }) ? true : false;
            resolve(found);
        });

        return promise.then((res: boolean) => {
            if (res) {
                return true;
            }
            self.router.navigate(['/']);
            return false;
        }, (error) => {
            return false;
        });
    }
}
