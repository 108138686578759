import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { SearchCriteriaModel } from 'src/app/models/search-criteria-model';
import { AuthorizationService, MessageService } from 'src/app/services';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { PayerTypeEditDialogComponent } from '../payer-type-edit-dialog/payer-type-edit-dialog.component';
import { tap } from 'rxjs/operators';
import { PayerTypeModel } from '../../models/payer-type-model';
import { PayerTypeService } from '../../services';

const DEFAULT_SORT = 'PayerTypeName';

@Component({
  selector: 'app-payer-type-search',
  templateUrl: './payer-type-search.component.html',
  styleUrls: ['./payer-type-search.component.css']
})
export class PayerTypeSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  addFeature = 'UI::PayerTypeAdd';
  editFeature = 'UI::PayerTypeEdit';
  deleteFeature = 'UI::PayerTypeDelete';
  dataSource: MatTableDataSource<PayerTypeModel>;

  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;
  defaultPageSize = 30;
  loadingGrid = false;
  pagerSubscription: Subscription;
  searchFormGroup: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [];
  searchString: string;
  searchCriteria: SearchCriteriaModel;
  sortOrder = '';
  newSortOrder = DEFAULT_SORT;

  constructor(private fb: FormBuilder,
    private payerTypeService: PayerTypeService,
    private messageService: MessageService,
    private authorizationService: AuthorizationService,
    private editDialog: MatDialog,
    private deleteDialog: MatDialog) {
  }

  ngOnInit() {
    this.initFormGroup();
    this.setupColumns();
    this.initialSearchLoad();
  }

  ngAfterViewInit() {
    this.paginator.pageSize = this.defaultPageSize;
    this.pagerSubscription = this.paginator.page
      .pipe(
        tap(() => this.loadSearchPage())
      )
      .subscribe();

    this.sort.sortChange.subscribe(data => {
      if (data['active'] !== 'ActionEdit' && data['active'] !== 'ActionDelete') {
        if (data['direction'] === '') {
          this.newSortOrder = '';
        } else {
          this.newSortOrder = `${(data['direction'] === 'desc') ? '-' : ''}${data['active']}`;
        }
        this.loadSearchPage();
      }
    });

  }

  initFormGroup() {
    this.searchFormGroup = this.fb.group({
      searchCriteria: [''],
    });
  }

  setupColumns() {
    this.displayedColumns = [
      'PayerTypeName'
    ];
    if (this.hasDeleteFeature()) {
      this.displayedColumns.unshift('ActionDelete');
    }
    if (this.hasEditFeature()) {
      this.displayedColumns.unshift('ActionEdit');
    }
  }

  ngOnDestroy() {
    if (this.pagerSubscription) {
      this.pagerSubscription.unsubscribe();
    }
  }

  search() {
    this.paginator.pageIndex = 0;
    this.loadSearchPage();
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = this.defaultPageSize;
    this.initFormGroup();
    this.initialSearchLoad();
  }

  initialSearchLoad() {
    this.sortOrder = DEFAULT_SORT;
    this.newSortOrder = DEFAULT_SORT;
    this.sort.active = DEFAULT_SORT;
    this.sort.direction = 'asc';
    this.sort._stateChanges.next();
    this.searchString = '';
    this.loadSearchPageHelper(this.searchString, this.defaultPageSize, this.paginator.pageIndex, this.newSortOrder);
  }

  loadSearchPage() {
    if (this.newSortOrder !== this.sortOrder) {
      this.paginator.pageIndex = 0;
    }
    this.searchString = this.searchFormGroup.controls['searchCriteria'].value;
    this.loadSearchPageHelper(this.searchString, this.paginator.pageSize,
      (this.paginator.pageIndex * this.paginator.pageSize), this.newSortOrder);
  }

  loadSearchPageHelper(criteria: string, limit: number, offset: number, sortOrder: string) {

    if (this.sortOrder !== sortOrder) {
      this.sortOrder = sortOrder;
    }

    this.loadingGrid = true;
    this.searchCriteria = new SearchCriteriaModel();
    this.searchCriteria.criteria = this.searchString;
    this.payerTypeService.search(this.searchCriteria, limit, offset, sortOrder)
      .subscribe(data => {
        this.dataSource = new MatTableDataSource<PayerTypeModel>(data.Items);

        this.dataSource.sortingDataAccessor = (myDataSource, sortHeaderId) => {
          if (typeof myDataSource[sortHeaderId] === 'string') {
            return myDataSource[sortHeaderId].toLocaleLowerCase();
          } else {
            return myDataSource[sortHeaderId];
          }
        };
        setTimeout(() => {
          this.paginator.length = data.TotalItemCount;
          this.dataSource.sort = this.sort;
        });
        this.loadingGrid = false;
      }, errorResponse => {
        this.messageService.HandleServerError(errorResponse);
        this.loadingGrid = false;
      });
  }

  add() {
    this.openEditDialog(null);
  }

  edit(element: PayerTypeModel) {
    this.openEditDialog(element);
  }

  // need to create the Edit dialog component
  openEditDialog(element: PayerTypeModel): void {
    const dialogRef = this.editDialog.open(PayerTypeEditDialogComponent,
      {
        width: '600px',
        data: { payerTypeId: element ? element.PayerTypeId : null }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.search();
      }
    });
  }

  openDeleteDialog(element: PayerTypeModel): void {
    const dialogRef = this.deleteDialog.open(ConfirmationDialogComponent,
      {
        width: '600px',
        data: {
          title: 'Confirm Delete', body: `Are you sure you want to delete
          ${element.PayerTypeName} ?`
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.payerTypeService.delete(element.PayerTypeId)
          .subscribe(data => {
            this.messageService.ShowDeletedSuccessfullyMessage();
            this.search();
          }, errorResponse => {
            this.messageService.HandleServerError(errorResponse);
          });
      }
    });
  }

  hasAddFeature(): boolean {
    return this.authorizationService.hasFeature(this.addFeature);
  }

  hasEditFeature(): boolean {
    return this.authorizationService.hasFeature(this.editFeature);
  }

  hasDeleteFeature(): boolean {
    return this.authorizationService.hasFeature(this.deleteFeature);
  }
}
