import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CorporateEntityRoutingModule } from './corporate-entity-routing.module';
import { MaterialModule } from '../material-module/material-module';
import { ReactiveFormsModule } from '@angular/forms';
import { CorporateEntityEditDialogComponent } from './components/corporate-entity-edit-dialog/corporate-entity-edit-dialog.component';
import { CorporateEntitySearchComponent } from './components/corporate-entity-search/corporate-entity-search.component';

@NgModule({
  declarations: [CorporateEntitySearchComponent, CorporateEntityEditDialogComponent],
  entryComponents: [
    CorporateEntityEditDialogComponent
  ],
  imports: [
    CommonModule,
    CorporateEntityRoutingModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})

export class CorporateEntityModule { }
