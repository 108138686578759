export class PrismEnvironmentModel {
    // [Required]
    PrismEnvironmentIdentifier: number;

    // [Required]
    // [StringLength(250)]
    PrismEnvironmentName: string;

    // [Required]
    // [StringLength(250)]
    PrismEnvironmentNameAnonymous: string;

    // [Required]
    // [StringLength(30)]
    PrismLPAR: string;
    
    // [Required]
    // [StringLength(50)]   
    SpectrumSQLInstance: string;

    // [Required]
    // [StringLength(200)]   
    PeerGroupName: string;

    // [Required]
    PeerGroupIncludeFlag: boolean;
    
    // [Required]
    IsGreenlight: boolean;

}
