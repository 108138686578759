import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { SearchCriteriaModel } from 'src/app/models/search-criteria-model';
import { AuthorizationService, MessageService } from 'src/app/services';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { InfoDialogComponent } from 'src/app/components/info-dialog/info-dialog.component';
import { PayerEditDialogComponent } from '../payer-edit-dialog/payer-edit-dialog.component';
import { tap } from 'rxjs/operators';
import { PayerModel } from '../../models/payer-model';
import { PayerService, PayerTypeService } from '../../services';
import { PayerSearchResultModel } from '../../models/payer-search-result-model';
import { PayerTypeModel } from '../../models';

const DEFAULT_SORT = 'PayerName'; // will use what the API gives us

@Component({
  selector: 'app-payer-search',
  templateUrl: './payer-search.component.html',
  styleUrls: ['./payer-search.component.css']
})

export class PayerSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  addFeature = 'UI::PayerAdd';
  editFeature = 'UI::PayerEdit';
  deleteFeature = 'UI::PayerDelete';
  dataSource: MatTableDataSource<PayerSearchResultModel>;

  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;
  defaultPageSize = 30;
  loadingGrid = false;
  pagerSubscription: Subscription;
  searchFormGroup: FormGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [];
  searchString: string;
  searchCriteria: SearchCriteriaModel;
  sortOrder = '';
  newSortOrder = DEFAULT_SORT;
  payerTypes: PayerTypeModel[];

  constructor(private fb: FormBuilder,
    private payerService: PayerService,
    private payerTypeService: PayerTypeService,
    private messageService: MessageService,
    private authorizationService: AuthorizationService,
    private editDialog: MatDialog,
    private deleteDialog: MatDialog,
    private infoDialog: MatDialog) {
  }

  ngOnInit() {
    this.loadPayerTypes();
    this.initFormGroup();

    this.setupColumns();
    this.initialSearchLoad();
  }

  ngAfterViewInit() {
    this.paginator.pageSize = this.defaultPageSize;
    this.pagerSubscription = this.paginator.page
      .pipe(
        tap(() => this.loadSearchPage())
      )
      .subscribe();

    this.sort.sortChange.subscribe(data => {
      if (data['active'] !== 'ActionEdit' && data['active'] !== 'ActionDelete') {
        if (data['direction'] === '') {
          this.newSortOrder = '';
        } else {
          this.newSortOrder = `${(data['direction'] === 'desc') ? '-' : ''}${data['active']}`;
        }
        this.loadSearchPage();
      }
    });
  }

  initFormGroup() {
    this.searchFormGroup = this.fb.group({
      searchCriteria: new FormControl(''),
    });
  }

  setupColumns() {
    this.displayedColumns = ['Bin', 'Pcn', 'GroupNumber', 'InmarPayerIdentifier',
      'GroupSettlement', 'GroupContracting', 'GroupMacAppeals', 'PayerName', 'PayerType',
      'Notes'
    ];
    if (this.hasDeleteFeature()) {
      this.displayedColumns.unshift('ActionDelete');
    }
    if (this.hasEditFeature()) {
      this.displayedColumns.unshift('ActionEdit');
    }
  }

  ngOnDestroy() {
    if (this.pagerSubscription) {
      this.pagerSubscription.unsubscribe();
    }
  }

  // User clicked Search button
  search() {
    this.paginator.pageIndex = 0;
    this.loadSearchPage();
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = this.defaultPageSize;
    this.initFormGroup();
    this.initialSearchLoad();
  }

  initialSearchLoad() {
    this.sortOrder = DEFAULT_SORT;
    this.newSortOrder = DEFAULT_SORT;
    this.sort.active = DEFAULT_SORT;
    this.sort.direction = 'asc';
    this.sort._stateChanges.next();
    this.searchString = '';
    this.loadSearchPageHelper(this.searchString, this.defaultPageSize, this.paginator.pageIndex, this.newSortOrder);
  }

  loadPayerTypes() {
    const getAllCriteria = new SearchCriteriaModel();
    getAllCriteria.criteria = '';

    this.payerTypeService.search(getAllCriteria, 9999, 0, '')
      .subscribe(data => {
        this.payerTypes = data.Items;
      }, errorResonse => {
        this.messageService.HandleServerError(errorResonse);
      });
  }

  loadSearchPage() {
    if (this.newSortOrder !== this.sortOrder) {
      this.paginator.pageIndex = 0;
    }
    this.searchString = this.searchFormGroup.controls['searchCriteria'].value;
    this.loadSearchPageHelper(this.searchString, this.paginator.pageSize,
      (this.paginator.pageIndex * this.paginator.pageSize), this.newSortOrder);
  }

  loadSearchPageHelper(criteria: string, limit: number, offset: number, sortOrder: string) {

    if (this.sortOrder !== sortOrder) {
      this.sortOrder = sortOrder;
    }

    this.loadingGrid = true;
    this.searchCriteria = new SearchCriteriaModel();
    this.searchCriteria.criteria = this.searchString;

    this.payerService.search(this.searchCriteria, limit, offset, sortOrder)
      .subscribe(data => {

        this.dataSource = new MatTableDataSource<PayerSearchResultModel>(data.Items);
        this.dataSource.sortingDataAccessor = (myDataSource, sortHeaderId) => {
          if (typeof myDataSource[sortHeaderId] === 'string') {
            return myDataSource[sortHeaderId].toLocaleLowerCase();
          } else {
            return myDataSource[sortHeaderId];
          }
        };
        setTimeout(() => {
          this.paginator.length = data.TotalItemCount;
          this.dataSource.sort = this.sort;
        });
        this.loadingGrid = false;
      }, errorResponse => {
        this.messageService.HandleServerError(errorResponse);
        this.loadingGrid = false;
      });
  }

  add() {
    this.openEditDialog(null);
  }

  edit(element: PayerModel) {
    this.openEditDialog(element);
  }

  // need to create the Edit dialog component
  openEditDialog(element: PayerModel): void {
    const dialogRef = this.editDialog.open(PayerEditDialogComponent,
      {
        width: '600px',
        data: { payerId: element ? element.PayerId : null }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.search();
      }
    });
  }

  openDeleteDialog(element: PayerModel): void {
    const dialogRef = this.deleteDialog.open(ConfirmationDialogComponent,
      {
        width: '600px',
        data: {
          title: 'Confirm Delete', body: `Are you sure you want to delete
          ${element.PayerName} ?`
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.payerService.delete(element.PayerId)
          .subscribe(data => {
            this.messageService.ShowDeletedSuccessfullyMessage();
            this.search();
          }, errorResponse => {
            this.messageService.HandleServerError(errorResponse);
          });
      }
    });
  }

  hasAddFeature(): boolean {
    return this.authorizationService.hasFeature(this.addFeature);
  }

  hasEditFeature(): boolean {
    return this.authorizationService.hasFeature(this.editFeature);
  }

  hasDeleteFeature(): boolean {
    return this.authorizationService.hasFeature(this.deleteFeature);
  }

  showNote(note: string) {
    const dialogRef = this.infoDialog.open(InfoDialogComponent,
      {
        width: '600px',
        data: {
          title: 'Note',
          body: note
        }
      });
  }

  getPayerTypeName(payerTypeId: string) {
    // cycle through items and join PayerTypeName field by PayerTypeId
    if (this.payerTypes) {
      return this.payerTypes.find(function (payerType) {
        return payerType.PayerTypeId === +payerTypeId;
      }).PayerTypeName;
    }

  }
}
