<h2>{{mode}} Corporate Entity</h2>
<mat-dialog-content>
  <div *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <div class="form-container">
        <mat-form-field *ngIf="mode === 'Edit'">
          <mat-label>CorporateEntityIdentifier</mat-label>
          <input matInput placeholder="CorporateEntityIdentifier (numbers only)" type="number"
            formControlName="corporateEntityIdentifier" required readonly>
        </mat-form-field>
        <mat-form-field *ngIf="mode !== 'Edit'">
          <mat-label>CorporateEntityIdentifier</mat-label>
          <input matInput placeholder="CorporateEntityIdentifier (numbers only)" type="number"
            formControlName="corporateEntityIdentifier" required>
          <mat-error *ngIf="formGroup.controls.corporateEntityIdentifier.errors?.required">Required.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>CorporateEntityName</mat-label>
          <input matInput required placeholder="CorporateEntityName" [errorStateMatcher]="matcher"
            formControlName="corporateEntityName">
          <mat-error *ngIf="formGroup.controls.corporateEntityName.errors?.required">Required.</mat-error>
          <mat-error *ngIf="formGroup.controls.corporateEntityName.errors?.maxlength">MaxLength
            {{formGroup.controls.corporateEntityName.errors?.maxlength['requiredLength']}} has been
            exceeded.</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="mode === 'Edit'">
          <mat-label>CorporateEntityNameAnonymous</mat-label>
          <input matInput readonly placeholder="CorporateEntityNameAnonymous" [errorStateMatcher]="matcher"
            formControlName="corporateEntityNameAnonymous">
        </mat-form-field>
        <mat-form-field>
          <mat-label>PeerGroupName</mat-label>
          <input matInput placeholder="PeerGroupName" [errorStateMatcher]="matcher" formControlName="peerGroupName">
          <mat-error *ngIf="formGroup.controls.peerGroupName.errors?.maxlength">MaxLength
            {{formGroup.controls.peerGroupName.errors?.maxlength['requiredLength']}} has been exceeded.</mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="peerGroupIncludeFlag">PeerGroupIncludeFlag</mat-checkbox>

      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="formGroup" align="end" class='inm-spaced--compact'>
  <button class="inm-button--action" (click)="save()" [disabled]="!formGroup.dirty || !formGroup.valid">
    Save
  </button>
  <button *ngIf="!formGroup.dirty" class="inm-button" (click)="dismiss()">
    Close
  </button>
  <button *ngIf="formGroup.dirty" class="inm-button" (click)="dismiss()">
    Cancel
  </button>
</mat-dialog-actions>