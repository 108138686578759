<div class="inm-layout-main__content">
  <h3 class="inm-headline--page-title">Payers</h3>
  <section class='inm-wrapper'>
    <form [formGroup]="searchFormGroup">
      <div class="inm-spaced">
        <mat-form-field>
          <input matInput placeholder="Search criteria" formControlName="searchCriteria">
        </mat-form-field>
        <section class='inm-spaced--compact'>
          <button class="inm-button--action" (click)="search()" [disabled]="searchFormGroup.invalid">Search</button>
          <button class="inm-button" (click)="reset()">Reset</button>
        </section>
        <section class='inm-spaced--compact' *ngIf="hasAddFeature()">
          <button class="inm-button" (click)="add()">Add</button>
        </section>
      </div>
    </form>
    <div class="inm-spaced" [hidden]="!loadingGrid">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="inm-spaced" [hidden]="loadingGrid || !dataSource">
      <div class="table-container" style="overflow-x:auto;">
        <table mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="ActionEdit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="actionColumn"> Edit </th>
            <td mat-cell *matCellDef="let element" class="actionColumn">
              <button mat-button (click)="edit(element)" style="cursor:pointer">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="ActionDelete">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="actionColumn"> Delete </th>
            <td mat-cell *matCellDef="let element" class="actionColumn">
              <button mat-button style="cursor:pointer" (click)="openDeleteDialog(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="Bin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> BIN </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.Bin}} </td>
          </ng-container>
          <ng-container matColumnDef="Pcn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PCN </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.Pcn}} </td>
          </ng-container>
          <ng-container matColumnDef="GroupNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Number </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.GroupNumber}} </td>
          </ng-container>
          <ng-container matColumnDef="InmarPayerIdentifier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Inmar Payer Identifier </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.InmarPayerIdentifier}} </td>
          </ng-container>
          <ng-container matColumnDef="GroupSettlement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Settlement </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.GroupSettlement}} </td>
          </ng-container>

          <ng-container matColumnDef="GroupContracting">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Contracting </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.GroupContracting}} </td>
          </ng-container>
          <ng-container matColumnDef="GroupMacAppeals">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Group MAC Appeals </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.GroupMacAppeals}} </td>
          </ng-container>
          <ng-container matColumnDef="PayerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Payer Name </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.PayerName}} </td>
          </ng-container>
          <ng-container matColumnDef="PayerType">
            <th mat-header-cell *matHeaderCellDef class="intColumn" mat-sort-header arrowPosition='before'> Payer Type
            </th>
            <td mat-cell *matCellDef="let element" class="intColumn" nowrap> {{getPayerTypeName(element.PayerTypeId)}}
            </td>
          </ng-container>
          <ng-container matColumnDef="Notes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Notes </th>
            <td mat-cell *matCellDef="let element" nowrap>
              <a class="appLink" *ngIf="element.Notes && element.Notes.length > 25; else shortNote"
                (click)="showNote(element.Notes)"> {{element.Notes.substring(0,25)}}...</a>
              <ng-template #shortNote> {{element.Notes}} </ng-template>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[30,60,90,10]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </section>
</div>