import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, FeatureGuard } from 'src/app/guards';
import { CorporateEntitySearchComponent } from './components/corporate-entity-search/corporate-entity-search.component';
const routes: Routes = [
  {
    path: 'corporateentities',
    component: CorporateEntitySearchComponent,
    canActivate: [AuthGuard, FeatureGuard],
    data: {feature: 'UI::CorporateEntityList'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorporateEntityRoutingModule { }
