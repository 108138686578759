import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PharmacySearchResultItem } from '../models';
import { SearchCriteriaModel } from '../../../models';
import { LimitedResult, KeyValuePairModel } from 'src/app/models';
import { PharmacyModel } from '../models';


@Injectable({
  providedIn: 'root'
})

export class PharmacyService {
  apiRoot: string = environment.masterDataApiRoot;
  pharmacyApiRoot = environment.masterDataApiRoot + '/pharmacies';
  


  constructor(private http: HttpClient) {
  }

  public search(pharmacySearchCriteria: SearchCriteriaModel,
    limit: number,
    offset: number,
    sort: string): Observable<LimitedResult<PharmacySearchResultItem>> {
    const url = `${this.pharmacyApiRoot}/search?limit=${limit}&offset=${offset}&sort=${sort}`;
    return this.http.post<LimitedResult<PharmacySearchResultItem>>(url, pharmacySearchCriteria);
  }

  public get(pharmacyId: number): Observable<PharmacyModel> {
    const url = `${this.pharmacyApiRoot}/${pharmacyId}`;
    return this.http.get<PharmacyModel>(url);
  }

  public save(pharmacyModel: PharmacyModel): Observable<PharmacyModel> {
    if (pharmacyModel.PharmacyId) {
      return this.put(pharmacyModel);
    }
    else {
      return this.post(pharmacyModel);
    }
  }

  public async saveAsync(pharmacyModel: PharmacyModel) {
    return await this.http.post<PharmacyModel>(this.pharmacyApiRoot, pharmacyModel).toPromise();
  }

  private post(pharmacyModel: PharmacyModel): Observable<PharmacyModel> {
    return this.http.post<PharmacyModel>(this.pharmacyApiRoot, pharmacyModel);
  }


  private put(pharmacyModel: PharmacyModel): Observable<PharmacyModel> {
    const url = `${this.pharmacyApiRoot}/${pharmacyModel.PharmacyId}`;
    return this.http.put<PharmacyModel>(url, pharmacyModel);
  }

  public delete(id: number): Observable<any> {
    const url = `${this.pharmacyApiRoot}/${id}`;
    return this.http.delete(url);
  }



}
