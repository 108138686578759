import { Component, OnInit, Input, Inject } from '@angular/core';
import {DatePipe } from '@angular/common';
//import * as df from 'dateformat';
import * as XLSX from 'xlsx';

import { PharmacyModel } from '../../models';
import { PharmacyService } from '../../services/pharmacy.service';
import { MessageService } from 'src/app/services';
import * as toastr from 'toastr';
import { MatDialog, MatDialogRef } from 'node_modules/@angular/material';
// import { exit } from 'process';
// import { utc } from 'moment';




@Component({
  selector: 'app-pharmacy-upload-dialog',
  templateUrl: './pharmacy-upload-dialog.component.html',
  styleUrls: ['./pharmacy-upload-dialog.component.css']
})
export class PharmacyUploadDialogComponent implements OnInit {
  
  changed = false;
  pharmacyFileObj : any;
  readonly MAX_ROW_COUNT :number = 500;
  errorObjects = [];
  clicked = false;
  loading = false;
  wrongfiletype = false;
  fileSelected = true;
  datepipe: DatePipe = new DatePipe("en-us");
  file_name = "";

  constructor(  private dialogRef: MatDialogRef<PharmacyUploadDialogComponent>,
     @Inject(PharmacyService)private pharmacyService:PharmacyService ,
  private messageService: MessageService
    
   ) {
  }

  ngOnInit(){}

  onFileChange(ev) {
    this.fileSelected = false;
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.file_name = file.name;
    let regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    if (regex.test(file.name.toLowerCase()) ) {
      this.wrongfiletype = false;
    reader.onload = (event) => {
      const data = reader.result;
      
      this.transformXlsx(data);

    }
    reader.readAsBinaryString(file);
  } else {
    this.fileSelected = true;
    //toastr.error("Select ONLY xlsx/xls formats and be sure the File Name contains no special characters.");
    this.wrongfiletype = true;
  }
    
  }


    dismiss() {
      this.dialogRef.close(this.changed);
    }

    transformXlsx(data: string | ArrayBuffer) {

      let workBook = null;
      let jsonData = null;
      workBook = XLSX.read(data, { type: 'binary' });
      
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet,{defval:" "});
        return initial;
      }, {});
      this.pharmacyFileObj = JSON.parse(JSON.stringify(jsonData));
      
      
    }



    
    
    async uploadFile() {
      let pharmacy:PharmacyModel = new PharmacyModel();
      let sheetName = Object.keys(this.pharmacyFileObj)[0];
      let pharmacyFileObj;
      let localErrorObjects = [];
      let flagField = ["1","0"];
      let excelInputError:string = "";
      
      this.loading = true;
     
      if(this.pharmacyFileObj[sheetName].length > this.MAX_ROW_COUNT) {
        const message = `The excel sheet has more than ${this.MAX_ROW_COUNT} Rows`;
        toastr.error(message);
        this.loading = false;   
        return;      
      }
            
      for(let i=0; i < this.pharmacyFileObj[sheetName].length; i++){
        pharmacy = new PharmacyModel();
        pharmacyFileObj =   this.pharmacyFileObj[sheetName][i];
        pharmacy.QualifiedProviderId = this.pharmacyFileObj[sheetName][i]["Pharmacy Qualifiedproviderid"] ;
        pharmacy.QualifiedProviderIdQualifier = this.pharmacyFileObj[sheetName][i]["Pharmacy Qualifiedproviderid Qualifier"];
        pharmacy.EffectiveDateBegin = this.pharmacyFileObj[sheetName][i]["Pharmacy Effectivedate Begin"] == " " ? null : new Date(Math.round((this.pharmacyFileObj[sheetName][i]["Pharmacy Effectivedate Begin"] - 25569)*86400*1000));        
        pharmacy.EffectiveDateEnd = this.pharmacyFileObj[sheetName][i]["Pharmacy Effectivedate End"] == " " ? null : new Date(Math.round((this.pharmacyFileObj[sheetName][i]["Pharmacy Effectivedate End"] - 25569)*86400*1000));
        pharmacy.NameOrDescription = this.pharmacyFileObj[sheetName][i]["Pharmacy Nameordescription"] == "" ? null : this.pharmacyFileObj[sheetName][i]["Pharmacy Nameordescription"];
        pharmacy.NationalProviderIdentifier = this.pharmacyFileObj[sheetName][i]["Pharmacy Nationalprovideridentifier"];
        pharmacy.NcpdpChainCode = this.pharmacyFileObj[sheetName][i]["Pharmacy Ncpdpchaincode"];
        pharmacy.NcpdpProviderId = this.pharmacyFileObj[sheetName][i]["Pharmacy Ncpdpproviderid"];
        pharmacy.AddressStreet1 = this.pharmacyFileObj[sheetName][i]["Pharmacy Address Street1"];
        pharmacy.AddressStreet2 = this.pharmacyFileObj[sheetName][i]["Pharmacy Address Street2"];
        pharmacy.AddressCity = this.pharmacyFileObj[sheetName][i]["Pharmacy Address City"];
        pharmacy.AddressStateCode = this.pharmacyFileObj[sheetName][i]["Pharmacy Address Statecode"];
        pharmacy.AddressPostalCode = this.pharmacyFileObj[sheetName][i]["Pharmacy Address Postalcode"];
        pharmacy.PhoneNumber = this.pharmacyFileObj[sheetName][i]["Pharmacy Phonenumber"];

        if (!(this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Sundayflag"] in flagField))
        {

          excelInputError +='Invalid input (Should be 1 or 0) for Pharmacy Daysofoperation Sundayflag; ';
          
        } else {
        pharmacy.DaysOfOperationSundayFlag = this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Sundayflag"] == "1" ? true : this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Sundayflag"] =="0" ? false : null;
        }

        if (!(this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Mondayflag"] in flagField))
        {

          excelInputError +='Invalid input (Should be 1 or 0) for Pharmacy Daysofoperation Mondayflag; ';
          
        } else {
        pharmacy.DaysOfOperationMondayFlag = this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Mondayflag"] == "1" ? true : this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Mondayflag"] =="0" ? false : null;
        }

        if (!(this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Tuesdayflag"] in flagField))
        {
          excelInputError +='Invalid input (Should be 1 or 0) for Pharmacy Daysofoperation Tuesdayflag; ';
          
        } else {
        pharmacy.DaysOfOperationTuesdayFlag = this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Tuesdayflag"] == "1" ? true : this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Tuesdayflag"] =="0" ? false : null;
        }

        if (!(this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Wednesdayflag"] in flagField))
        {

          excelInputError +='Invalid input (Should be 1 or 0) for Pharmacy Daysofoperation Wednesdayflag; ';
          
        } else {
        pharmacy.DaysOfOperationWednesdayFlag = this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Wednesdayflag"] == "1" ? true : this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Wednesdayflag"] =="0" ? false : null;
        }

        if (!(this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Thursdayflag"] in flagField))
        {

          excelInputError +='Invalid input (Should be 1 or 0) for Pharmacy Daysofoperation Thursdayflag; ';
          
        } else {
        pharmacy.DaysOfOperationThursdayFlag = this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Thursdayflag"] == "1" ? true : this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Thursdayflag"] =="0" ? false : null;
        }

        if (!(this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Fridayflag"] in flagField))
        {

          excelInputError +='Invalid input (Should be 1 or 0) for Pharmacy Daysofoperation Fridayflag; ';
          
        } else {
        pharmacy.DaysOfOperationFridayFlag = this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Fridayflag"] == "1" ? true : this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Fridayflag"] =="0" ? false : null;
        }

        if (!(this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Saturdayflag"] in flagField))
        {

          excelInputError +='Invalid input (Should be 1 or 0) for Pharmacy Daysofoperation Saturdayflag; ';
          
        } else {
        pharmacy.DaysOfOperationSaturdayFlag = this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Saturdayflag"] == "1" ? true : this.pharmacyFileObj[sheetName][i]["Pharmacy Daysofoperation Saturdayflag"] =="0" ? false : null;
        }
     
       
       
        pharmacy.CorporateEntityIdentifier = this.pharmacyFileObj[sheetName][i]["Pharmacy Corporateentityidentifier"];
        pharmacy.CorporateGroupOrDivision = this.pharmacyFileObj[sheetName][i]["Pharmacy Corporategroupordivision"];
        pharmacy.CorporateLocationNumber = this.pharmacyFileObj[sheetName][i]["Pharmacy Corporatelocationnumber"];
        pharmacy.DataUsageCode = this.pharmacyFileObj[sheetName][i]["Pharmacy Datausagecode"];
        if (!(this.pharmacyFileObj[sheetName][i]["Pharmacy Demoflag"] in flagField))
        {
          excelInputError +='Invalid input (Should be 1 or 0) for Pharmacy Demoflag; ';
       
        } else {
        pharmacy.DemoFlag = this.pharmacyFileObj[sheetName][i]["Pharmacy Demoflag"] == "1" ? true : this.pharmacyFileObj[sheetName][i]["Pharmacy Demoflag"] =="0" ? false : null;
        }
        
        pharmacy.MarketCategory = this.pharmacyFileObj[sheetName][i]["Pharmacy Marketcategory"];
        pharmacy.PeerGroup = this.pharmacyFileObj[sheetName][i]["Pharmacy Peergroup"];
        pharmacy.PharmacyType = this.pharmacyFileObj[sheetName][i]["Pharmacy Pharmacytypecode"];
        pharmacy.RetailBanner = this.pharmacyFileObj[sheetName][i]["Pharmacy Retailbanner"];
        pharmacy.HiveClientId = this.pharmacyFileObj[sheetName][i]["HiveClientId"];
        
 
        let errorJson;
        let cloneObj;
        if (excelInputError!="") {
          cloneObj = {...pharmacyFileObj, errorResponse:excelInputError};
          
          if (cloneObj["Pharmacy Effectivedate Begin"] == " ") {
            cloneObj["Pharmacy Effectivedate Begin"] = "";
          } else if (cloneObj["Pharmacy Effectivedate Begin"] == "NULL") {
            cloneObj["Pharmacy Effectivedate Begin"] = "NULL";
          } else if (cloneObj["Pharmacy Effectivedate Begin"] == "null") {
            cloneObj["Pharmacy Effectivedate Begin"] = "null";
          } else {
            cloneObj["Pharmacy Effectivedate Begin"]=  new Date(Math.round((cloneObj["Pharmacy Effectivedate Begin"] - 25569)*86400*1000)).toLocaleDateString('en-US', { timeZone: 'UTC' });
          }
          

          if (cloneObj["Pharmacy Effectivedate End"] ==  " ")
          {
            cloneObj["Pharmacy Effectivedate End"] = "";
          } else if (cloneObj["Pharmacy Effectivedate End"] ==  "NULL") {
            cloneObj["Pharmacy Effectivedate End"]= "NULL";

          } else if (cloneObj["Pharmacy Effectivedate End"] ==  "null") {
            cloneObj["Pharmacy Effectivedate End"]= "null";

          } else {
            cloneObj["Pharmacy Effectivedate End"]=new Date(Math.round((cloneObj["Pharmacy Effectivedate End"] - 25569)*86400*1000)).toLocaleDateString('en-US', { timeZone: 'UTC' });
          }
          
          
          errorJson = JSON.parse(JSON.stringify(cloneObj));
        localErrorObjects.push(errorJson);
        excelInputError="";
        
        }
        else {
          
    await this.pharmacyService.saveAsync(pharmacy).then(function success(response){}, function error(response){
       cloneObj = {...pharmacyFileObj, errorResponse:''};
       
       if (cloneObj["Pharmacy Effectivedate Begin"] == " ") {
        cloneObj["Pharmacy Effectivedate Begin"] = "";
      } else if (cloneObj["Pharmacy Effectivedate Begin"] == "NULL") {
        cloneObj["Pharmacy Effectivedate Begin"] = "NULL";
      } else if (cloneObj["Pharmacy Effectivedate Begin"] == "null") {
        cloneObj["Pharmacy Effectivedate Begin"] = "null";
      } else {
        cloneObj["Pharmacy Effectivedate Begin"]=  new Date(Math.round((cloneObj["Pharmacy Effectivedate Begin"] - 25569)*86400*1000)).toLocaleDateString('en-US', { timeZone: 'UTC' });
      }
       

       if (cloneObj["Pharmacy Effectivedate End"] ==  " ")
          {
            cloneObj["Pharmacy Effectivedate End"] = "";
          } else if (cloneObj["Pharmacy Effectivedate End"] ==  "NULL") {
            cloneObj["Pharmacy Effectivedate End"]= "NULL";

          } else if (cloneObj["Pharmacy Effectivedate End"] ==  "null") {
            cloneObj["Pharmacy Effectivedate End"]= "null";

          } else {
            cloneObj["Pharmacy Effectivedate End"]=new Date(Math.round((cloneObj["Pharmacy Effectivedate End"] - 25569)*86400*1000)).toLocaleDateString('en-US', { timeZone: 'UTC' });
          }
       
          

      if (response.error)  {
        let responseError : string ="";
        if(response.error.Content != null)
        {
           for(let j=0; j < response.error.Content.length; j++) {
             responseError += response.error.Content[j].PropertyError + ';' ;
           }
        } else {
          responseError = response.error.Error;
        }
      cloneObj.errorResponse = responseError;
      }
     
         errorJson = JSON.parse(JSON.stringify(cloneObj))
        localErrorObjects.push(errorJson);

    }    );
  }
    
      
  }

    if(localErrorObjects.length !=0 && localErrorObjects.length <= this.pharmacyFileObj[sheetName].length) {
      this.errorObjects  = Object.assign([], localErrorObjects);
      toastr.error(`Error saving ${localErrorObjects.length} Pharmacies out of ${this.pharmacyFileObj[sheetName].length}`);
      this.wrongfiletype = false;
    } else {
      this.messageService.ShowSavedSuccessfullyMessage();
      this.dismiss();
    }
      
    this.loading = false;
  }

  exportToExcel($event, data) {


		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.errorObjects,{cellDates:true});
   const wb: XLSX.WorkBook = XLSX.utils.book_new();

   
		XLSX.utils.book_append_sheet(wb, ws, 'Failed');

    XLSX.writeFile(wb, 'PharmacyUploadErrorList.xlsx');
    
  }


  
}
