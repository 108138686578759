import { Component, OnInit, Input } from '@angular/core';
import { Section } from '../../models';
import { AuthorizationService } from '../../services';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';
import * as _ from 'lodash';

declare function intializeHealthcareTopBar(parentContainer: JQuery, authzApiUrl: string): void;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private topBarLoaded = false;
  public sectionsObj: Section;
  private _usersClients: any[];
  public get usersClients(): any[] {
    return this._usersClients;
  }
  public set usersClients(value: any[]) {
    this._usersClients = value;
  }
  public clientsLoaded = false;
  @Input() sections: string;

  constructor(
    private authorizationService: AuthorizationService,
    private _cookieService: CookieService,
  ) {
    if (this.topBarLoaded) {
      return;
    }
    this.injectHealthcareTopBar()
      .then(() => {
        const topBar = intializeHealthcareTopBar($('#healthcareTopBar'), environment.authZApiRoot);
        window['healthcareTopBar'] = topBar;
        this.topBarLoaded = true;
        this.authorizationService.setHealthcareTopBar(topBar);
      });

  }

  ngOnInit(): void {
    this.sectionsObj = JSON.parse(this.sections);
  }
  private injectHealthcareTopBar(): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = environment.healthcareTopBar;
      script.addEventListener('load', resolve);
      script.addEventListener('error', () => reject('Error loading script.'));
      script.addEventListener('abort', () => reject('Script loading aborted.'));
      document.body.appendChild(script);
    });
  }
  public get showHeader(): boolean {
    return this._cookieService.get('id_token') !== '';
  }
  checkValidLink(feature: string): boolean {
    if (!feature) {
      return false;
    }
    if (!this.authorizationService.hasFeature(feature)) {
      return false;
    }
    return true;
  }
}
