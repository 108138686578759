import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LimitedResult, KeyValuePairModel, SearchCriteriaModel } from 'src/app/models';
import { PayerTypeModel } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PayerTypeService {

  baseUrl = environment.masterDataApiRoot + '/payerTypes';

  constructor(private http: HttpClient) {
  }

  // For populating SelectList
  public getPayerTypeList(): Observable<KeyValuePairModel[]> {
    const searchCriteria: SearchCriteriaModel = new SearchCriteriaModel();
    searchCriteria.criteria = '';
    const limit = 9999;

    return this.http.post<PayerTypeModel[]>(`${this.baseUrl}/search/?limit=${limit}`, searchCriteria)
      .pipe(
        map(res => res['Items'].map(item => new KeyValuePairModel(item.PayerTypeId.toString(),
          item.PayerTypeName)))
      );
  }

  public search(searchCriteria: SearchCriteriaModel,
    limit: number,
    offset: number,
    sort: string): Observable<LimitedResult<PayerTypeModel>> {
    const url = `${this.baseUrl}/search?limit=${limit}&offset=${offset}&sort=${sort}`;
    return this.http.post<LimitedResult<PayerTypeModel>>(url, searchCriteria);
  }

  public get(id: number): Observable<PayerTypeModel> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<PayerTypeModel>(url);
  }

  public save(model: PayerTypeModel): Observable<PayerTypeModel> {
    if (model.PayerTypeId) {
      return this.put(model);
    } else {
      return this.post(model);
    }
  }

  private post(model: PayerTypeModel): Observable<PayerTypeModel> {
    return this.http.post<PayerTypeModel>(this.baseUrl, model);
  }

  private put(model: PayerTypeModel): Observable<PayerTypeModel> {
    const url = `${this.baseUrl}/${model.PayerTypeId}`;
    return this.http.put<PayerTypeModel>(url, model);
  }

  public delete(id: number): Observable<any> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete(url);
  }
}
