export class CorporateEntityModel {
  // [Required]
  CorporateEntityIdentifier: number;

  // [Required]
  // [StringLength(250)]
  CorporateEntityName: string;

  // [Required]
  // [StringLength(250)]
  CorporateEntityNameAnonymous: string;

  // [StringLength(200)]
  PeerGroupName: string;

  // [Required]
  PeerGroupIncludeFlag: boolean = false;
}
