          
<h2>Upload Pharmacies</h2>
<div >  
    <input type="button" value="Choose" class="inm-button" onclick="document.getElementById('file').click();" />
    <input type="file" style="display:none;" id="file" name="file" (change)="onFileChange($event)" />  
    <div class="divider"> </div>
      <button class="inm-button" color="primary" [class.spinner]="loading" [disabled]="loading" [disabled]="fileSelected" (click)="uploadFile()" >Upload</button>
      
    
  <span class="template-align" style="top: 14%" >
    <a href="../assets/file-templates/MasterdataStoreAdditionsTemplate.xlsx">
            Download Template
    </a>
  </span>
  
  <div id="output">
    <br>
    <textarea style="width: 95%; font-style: italic; padding: 1px; size: 0pt; border: none; margin-top: 0px; margin-bottom: 0px; height: 47px; white-space: nowrap;" onfocus="this.blur();" id="file_name" name="file_name">{{file_name}}</textarea>
  </div>
  <br>

  <p *ngIf="wrongfiletype" style="font-style:bold;color:blue;padding:1px;size:0pt ;">Select ONLY xlsx/xls formats and be sure the File Name contains no special characters.</p>
  <p *ngIf="loading" style="font-style:bold;color:blue;padding:1px;size:0pt ;">This could take upto a minute...</p>  
 
  
  <div *ngIf="errorObjects.length > 0">
    <p style="font-style: italic;color:red;padding:1px;size:0pt ;" > Error uploading Pharmacies..<a href id="download" (click)="exportToExcel($event)" onclick="return false">Click Here</a> to download error list.</p>
  </div>
   
</div>  


<br>
<!-- <div>
  <p class="overlay-text"><strong>Drag and drop one file here.</strong></p>
</div>
<br> -->

<div> 
  
  <p style="font-style: italic;color:orangered;padding:1px;size:0pt;">
    <svg class="inm-icon" aria-label="Warning"><use xlink:href="#inm-icon__warning" /></svg>
     Only template formatted xlsx / xls file is allowed with a maximum of 500 Pharmacies.
  </p>
</div> 
  <mat-dialog-actions align="end">
    <button class="inm-button"(click)="dismiss()" justify-content="right">Close</button>
  </mat-dialog-actions> 



