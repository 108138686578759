import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../environments/environment';
import { User } from '../models';


@Injectable()
export class AuthorizationService {
  private healthcareTopBar: any;
  private CACHE_INTERVAL = 1000;
  private _topBarLoaded = false;
  private features: any;
  private timer: any;

  constructor(private router: Router, private _http: HttpClient, private _cookieService: CookieService) {
    this.timer = setInterval(() => {
      this.updateCache();
    }, this.CACHE_INTERVAL);
  }

  public setHealthcareTopBar(topBar: any) {
    this.healthcareTopBar = topBar;
  }

  public get HealthcareTopBar(): any { return this.healthcareTopBar ? this.healthcareTopBar : null; }

  public get topBarLoaded() {
    return this._topBarLoaded;
  }

  private updateCache() {
    if (!this.HealthcareTopBar || !this.HealthcareTopBar.currentUserDetails) {
      return false;
    }

    // it is fair to say that we have the topbar loaded at this point. - kmb
    this._topBarLoaded = true;

    this.features = _.filter(this.HealthcareTopBar.currentUserDetails.Features, { App: environment.applicationName });
    clearTimeout(this.timer);
  }

  getSecured(routepath: string) {
    const prepLink = `${environment.healthcareSignInAddress}?returnUrl=${environment.rootUrl}`;
    window.location.href = prepLink;
  }

  // Do not bind this without using cache to the DOM
  hasFeature(featureName: string, useCache: boolean = false): boolean {
    let collection: any[];
    if (!this.HealthcareTopBar || !this.HealthcareTopBar.currentUserDetails) {
      return false;
    }

    if (useCache) {
      collection = this.features;
    } else {
      collection = this.HealthcareTopBar.currentUserDetails.Features;
    }

    let found = false;
    found = _.find(collection, (features) => {
      if (featureName === features.Name && features.App === environment.applicationName) {
        return true;
      }
    });

    return found;
  }

  async CurrentUser(): Promise<User> {
    return await this._http.get<User>(
      environment.authZApiRoot + '/CurrentUser')
      .toPromise<User>();
  }
}
