<h2>{{mode}} Payer</h2>
<mat-dialog-content>
  <div *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <div class="form-container">

        <mat-form-field>
          <input formControlName="Bin" matInput placeholder="BIN" (blur)="leftInmarPayerIdentifierField($event, '*')">
          <mat-error *ngIf="formGroup.controls.Bin.errors?.maxlength">MaxLength
            {{formGroup.controls.Bin.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input formControlName="Pcn" matInput placeholder="PCN" (blur)="leftInmarPayerIdentifierField($event, '')">
          <mat-error *ngIf="formGroup.controls.Pcn.errors?.maxlength">MaxLength
            {{formGroup.controls.Pcn.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input formControlName="GroupNumber" matInput placeholder="Group Number"
            (blur)="leftInmarPayerIdentifierField($event, '')">
          <mat-error *ngIf="formGroup.controls.GroupNumber.errors?.maxlength">MaxLength
            {{formGroup.controls.GroupNumber.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Inmar Payer Identifier (BIN | PCN | Group Number)</mat-label>
          <input formControlName="InmarPayerIdentifier" matInput readonly>
          <mat-error *ngIf="formGroup.controls.InmarPayerIdentifier.errors?.invalidCombination"> Invalid
            BIN | PCN | Group Number combination.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input formControlName="GroupSettlement" matInput placeholder="Group Settlement"
            oninput="this.value = this.value.toUpperCase()">
          <mat-error *ngIf="formGroup.controls.GroupSettlement.errors?.maxlength">MaxLength
            {{formGroup.controls.GroupSettlement.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input formControlName="GroupContracting" matInput placeholder="Group Contracting"
            oninput="this.value = this.value.toUpperCase()">
          <mat-error *ngIf="formGroup.controls.GroupContracting.errors?.maxlength">MaxLength
            {{formGroup.controls.GroupContracting.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input formControlName="GroupMacAppeals" matInput placeholder="Group MAC Appeals"
            oninput="this.value = this.value.toUpperCase()">
          <mat-error *ngIf="formGroup.controls.GroupMacAppeals.errors?.maxlength">MaxLength
            {{formGroup.controls.GroupMacAppeals.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input formControlName="PayerName" matInput placeholder="Payer Name" required>
          <mat-error *ngIf="formGroup.controls.PayerName.errors?.maxlength">MaxLength
            {{formGroup.controls.PayerName.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
          <mat-error *ngIf="formGroup.controls.PayerName.errors?.required">Required.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Payer Type</mat-label>
          <mat-select name="PayerTypeId" required formControlName="PayerTypeId" placeholder="Select">
            <mat-option *ngFor="let item of payerTypes" [value]="item.key">
              {{item.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.controls.PayerTypeId.errors?.maxlength">MaxLength
            {{formGroup.controls.PayerTypeId.errors?.maxlength['requiredLength']}} has been exceeded.
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input formControlName="Notes" matInput placeholder="Notes">
          <mat-error *ngIf="formGroup.controls.Notes.errors?.maxlength">MaxLength
            {{formGroup.controls.Notes.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="formGroup" align="end" class='inm-spaced--compact'>
  <button class="inm-button--action" (click)="save()" [disabled]="!formGroup.dirty || !formGroup.valid">
    Save
  </button>
  <button *ngIf="!formGroup.dirty" class="inm-button" (click)="dismiss()">
    Close
  </button>
  <button *ngIf="formGroup.dirty" class="inm-button" (click)="dismiss()">
    Cancel
  </button>
</mat-dialog-actions>