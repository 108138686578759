import { Injectable } from '@angular/core';
import { KeyValuePairModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PharmacyTypesService {
  pharmacyTypes: KeyValuePairModel[];
  constructor() { }

  public getPharmacyTypes(): KeyValuePairModel[] {
    if (!this.pharmacyTypes) {
      this.pharmacyTypes = [];

      this.pharmacyTypes.push(new KeyValuePairModel('Retail Pharmacy', 'Retail Pharmacy'));
      this.pharmacyTypes.push( new KeyValuePairModel('Outpatient Pharmacy', 'Outpatient Pharmacy'));
      this.pharmacyTypes.push( new KeyValuePairModel('Specialty Pharmacy', 'Specialty Pharmacy'));
      this.pharmacyTypes.push( new KeyValuePairModel('Mail-Order Pharmacy', 'Mail-Order Pharmacy'));
      this.pharmacyTypes.push( new KeyValuePairModel('Other', 'Other'));
    }
    return this.pharmacyTypes;
  }

}
