import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PharmacyModel } from '../../models';
import { PharmacyService } from '../../services/pharmacy.service';
import { MatDialog, MatDialogRef } from 'node_modules/@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MessageService, PharmacyTypesService, MarketCategoryService, AuthorizationService } from 'src/app/services';
import { ErrorStateMatcher } from '@angular/material/core';
import { StatesService } from 'src/app/services/states.service';
import { CorporateEntityService } from 'src/app/modules/corporate-entity/services/corporate-entity.service';
import { KeyValuePairModel, ErrorStateMatcherDirty } from 'src/app/models';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { DataUsageCodeService } from 'src/app/services/data-usage-code.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-pharmacy-edit-dialog',
  templateUrl: './pharmacy-edit-dialog.component.html',
  styleUrls: ['./pharmacy-edit-dialog.component.css']
})

export class PharmacyEditDialogComponent implements OnInit {
  changed = false;
  editOnly: boolean;
  pharmFormGroup: FormGroup;
  pharmModel: PharmacyModel;
  pharmacyId: number;
  mode = '';
  matcher = new ErrorStateMatcherDirty();
  corporateEntityList: KeyValuePairModel[];
  stateList: KeyValuePairModel[];
  pharmacyTypeList: KeyValuePairModel[];
  marketCategoryList: KeyValuePairModel[];
  dataUsageTypes: KeyValuePairModel[];
  origCorporateEntityIdentifier: string;

  constructor(private dialogRef: MatDialogRef<PharmacyEditDialogComponent>,
    private fb: FormBuilder,
    private pharmacyService: PharmacyService,
    private messageService: MessageService,
    private stateService: StatesService,
    private corporateEntityService: CorporateEntityService,
    private pharmacyTypeService: PharmacyTypesService,
    private marketCategoryService: MarketCategoryService,
    private dataUsageCodeService: DataUsageCodeService,
    private authorizationService: AuthorizationService,
    private confirmDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    this.loadCorporateEntitiesList();
    this.loadStates();
    this.loadMarketCategories();
    this.loadPharmacyTypes();
    this.loadDataUsageTypes();

    if (this.data && this.data['pharmacyId']) {
      this.mode = 'Edit';
      this.pharmacyId = this.data.pharmacyId;
      this.pharmacyService.get(this.pharmacyId)
        .subscribe(data => {
          this.pharmModel = data;
          this.origCorporateEntityIdentifier = data.CorporateEntityIdentifier.toString();
          this.loadForm();
        }, errorResponse => {
          this.messageService.HandleServerError(errorResponse);
        });
    } else {
      this.mode = 'Add';
      this.pharmModel = new PharmacyModel();
      this.loadForm();
    }
  }

  loadForm() {
    this.pharmFormGroup = this.fb.group({
      PharmacyId: [this.pharmModel.PharmacyId],
      QualifiedProviderId: [this.pharmModel.QualifiedProviderId,
      Validators.compose([Validators.required, Validators.maxLength(50)])],
      QualifiedProviderIdQualifier: [this.pharmModel.QualifiedProviderIdQualifier,
      Validators.compose([Validators.required, Validators.maxLength(50)])],
      EffectiveDateBegin: [this.pharmModel.EffectiveDateBegin, Validators.required],
      EffectiveDateEnd: [this.pharmModel.EffectiveDateEnd],
      NameOrDescription: [this.pharmModel.NameOrDescription,
      Validators.compose([Validators.required, Validators.maxLength(250)])],
      NationalProviderIdentifier: [this.pharmModel.NationalProviderIdentifier,
      Validators.compose([Validators.required, Validators.maxLength(20)])],
      NcpdpChainCode: [this.pharmModel.NcpdpChainCode, Validators.maxLength(100)],
      NcpdpProviderId: [this.pharmModel.NcpdpProviderId,
      Validators.compose([Validators.required, Validators.maxLength(20)])],
      AddressStreet1: [this.pharmModel.AddressStreet1, Validators.maxLength(250)],
      AddressStreet2: [this.pharmModel.AddressStreet2, Validators.maxLength(250)],
      AddressCity: [this.pharmModel.AddressCity, Validators.maxLength(250)],
      AddressStateCode: [this.pharmModel.AddressStateCode],
      AddressPostalCode: [this.pharmModel.AddressPostalCode, Validators.maxLength(10)],
      PhoneNumber: [this.pharmModel.PhoneNumber, Validators.maxLength(30)],
      DaysOfOperationSundayFlag: [this.pharmModel.DaysOfOperationSundayFlag],
      DaysOfOperationMondayFlag: [this.pharmModel.DaysOfOperationMondayFlag],
      DaysOfOperationTuesdayFlag: [this.pharmModel.DaysOfOperationTuesdayFlag],
      DaysOfOperationWednesdayFlag: [this.pharmModel.DaysOfOperationWednesdayFlag],
      DaysOfOperationThursdayFlag: [this.pharmModel.DaysOfOperationThursdayFlag],
      DaysOfOperationFridayFlag: [this.pharmModel.DaysOfOperationFridayFlag],
      DaysOfOperationSaturdayFlag: [this.pharmModel.DaysOfOperationSaturdayFlag],
      CorporateEntityIdentifier: [this.pharmModel.CorporateEntityIdentifier
        ? this.pharmModel.CorporateEntityIdentifier.toString()
        : '', Validators.required],
      CorporateGroupOrDivision: [this.pharmModel.CorporateGroupOrDivision, Validators.maxLength(250)],
      CorporateLocationNumber: [this.pharmModel.CorporateLocationNumber, Validators.maxLength(50)],
      DataUsageCode: [this.pharmModel.DataUsageCode, Validators.compose([Validators.required, Validators.maxLength(50)])],
      DemoFlag: [this.pharmModel.DemoFlag],
      MarketCategory: [this.pharmModel.MarketCategory, Validators.compose([Validators.required, Validators.maxLength(50)])],
      PeerGroup: [this.pharmModel.PeerGroup, Validators.maxLength(250)],
      PharmacyType: [this.pharmModel.PharmacyType, Validators.compose([Validators.maxLength(50)])],
      RetailBanner: [this.pharmModel.RetailBanner, Validators.maxLength(250)],
      HiveClientId: [this.pharmModel.HiveClientId, Validators.maxLength(50)]
    },
      { validator: this.dateOrderValidation('EffectiveDateBegin', 'EffectiveDateEnd') }
    );
  }

  dateOrderValidation(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];

      if ((f.value && t.value) && (Date.parse(f.value) > Date.parse(t.value))) {
        return {
          dates: 'End date should be greater than or equal to start date.'
        };
      }
      return {};
    };
  }

  loadModelFromForm() {
    const controls = this.pharmFormGroup.controls;
    this.pharmModel.AddressCity = controls['AddressCity'].value;
    this.pharmModel.AddressPostalCode = controls['AddressPostalCode'].value;
    this.pharmModel.AddressStateCode = controls['AddressStateCode'].value;
    this.pharmModel.AddressStreet1 = controls['AddressStreet1'].value;
    this.pharmModel.AddressStreet2 = controls['AddressStreet2'].value;
    this.pharmModel.CorporateEntityIdentifier = controls['CorporateEntityIdentifier'].value;
    this.pharmModel.CorporateGroupOrDivision = controls['CorporateGroupOrDivision'].value;
    this.pharmModel.CorporateLocationNumber = controls['CorporateLocationNumber'].value;
    this.pharmModel.DataUsageCode = controls['DataUsageCode'].value;
    this.pharmModel.DaysOfOperationFridayFlag = controls['DaysOfOperationFridayFlag'].value;
    this.pharmModel.DaysOfOperationMondayFlag = controls['DaysOfOperationMondayFlag'].value;
    this.pharmModel.DaysOfOperationSaturdayFlag = controls['DaysOfOperationSaturdayFlag'].value;
    this.pharmModel.DaysOfOperationSundayFlag = controls['DaysOfOperationSundayFlag'].value;
    this.pharmModel.DaysOfOperationThursdayFlag = controls['DaysOfOperationThursdayFlag'].value;
    this.pharmModel.DaysOfOperationTuesdayFlag = controls['DaysOfOperationTuesdayFlag'].value;
    this.pharmModel.DaysOfOperationWednesdayFlag = controls['DaysOfOperationWednesdayFlag'].value;
    this.pharmModel.DemoFlag = controls['DemoFlag'].value;
    this.pharmModel.EffectiveDateBegin = controls['EffectiveDateBegin'].value != null
      ? new Date(controls['EffectiveDateBegin'].value)
      : null;

    this.pharmModel.EffectiveDateEnd = controls['EffectiveDateEnd'].value
      ? new Date(controls['EffectiveDateEnd'].value)
      : null;
    this.pharmModel.MarketCategory = controls['MarketCategory'].value;
    this.pharmModel.NameOrDescription = controls['NameOrDescription'].value;
    this.pharmModel.NationalProviderIdentifier = controls['NationalProviderIdentifier'].value;
    this.pharmModel.NcpdpChainCode = controls['NcpdpChainCode'].value;
    this.pharmModel.NcpdpProviderId = controls['NcpdpProviderId'].value;
    this.pharmModel.PeerGroup = controls['PeerGroup'].value;
    this.pharmModel.PharmacyId = controls['PharmacyId'].value;
    this.pharmModel.PharmacyType = controls['PharmacyType'].value;
    this.pharmModel.PhoneNumber = controls['PhoneNumber'].value;
    this.pharmModel.QualifiedProviderId = controls['QualifiedProviderId'].value;
    this.pharmModel.QualifiedProviderIdQualifier = controls['QualifiedProviderIdQualifier'].value;
    this.pharmModel.RetailBanner = controls['RetailBanner'].value;
    this.pharmModel.HiveClientId = controls['HiveClientId'].value;
  }

  save() {
    this.loadModelFromForm();

    const isEdit = this.pharmModel.PharmacyId;
    this.pharmacyService.save(this.pharmModel)
      .subscribe(data => {
        if (isEdit) {
          this.pharmModel = data; // Saved and you can edit some more. The item is not cleared off the screen.
        } else {
          this.pharmModel = new PharmacyModel(); // You can now add another one. we are still in Add mode.
        }
        this.changed = true;
        this.loadForm();
        this.messageService.ShowSavedSuccessfullyMessage();
      }, errorResponse => {
        this.messageService.HandleServerError(errorResponse);
      });
  }

  dismiss() {
    this.dialogRef.close(this.changed);
  }

  loadCorporateEntitiesList() {
    this.corporateEntityService.getCorporateEntityList()
      .subscribe(data => {
        this.corporateEntityList = data;
      });
  }

  loadStates() {
    this.stateList = this.stateService.getStateList();
  }

  loadPharmacyTypes() {
    this.pharmacyTypeList = this.pharmacyTypeService.getPharmacyTypes();
  }

  loadMarketCategories() {
    this.marketCategoryList = this.marketCategoryService.getMarketCategories();
  }

  loadDataUsageTypes() {
    this.dataUsageTypes = this.dataUsageCodeService.getDataUsageCodes();
  }

  changedCorporateEntityIdentifier(selectedValue: string) {
    // If editing and changing this field we need them to confirm hippa risk
    if (this.mode === 'Edit' && selectedValue !== this.origCorporateEntityIdentifier) {
      this.openCorporateEntityIdentifierConfirm();
    }
  }

  openCorporateEntityIdentifierConfirm(): void {
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent,
      {
        width: '600px',
        data: {
          title: 'Confirm change of Corporate Entity Identifier',
          // tslint:disable-next-line:max-line-length
          body: `Warning: Changing this field could expose data to unauthorized users causing HIPAA violations. Are you sure you want to do this??`
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.loadModelFromForm();
        this.pharmModel.CorporateEntityIdentifier = parseInt(this.origCorporateEntityIdentifier, 10);
        this.loadForm();
      }

    });
  }
}
