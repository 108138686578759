import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SearchCriteriaModel } from 'src/app/models';
import { PrismEnvironmentSearchResultModel } from '../../models/prism-environment-search-result-model';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { PrismEnvironmentService } from '../../services/prism-environment.service';
import { AuthorizationService, MessageService } from 'src/app/services';
import { tap } from 'rxjs/operators';
import { PrismEnvironmentEditDialogComponent } from '../prism-environment-edit-dialog/prism-environment-edit-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';

const DEFAULT_SORT = 'PrismEnvironmentIdentifier';

@Component({
  selector: 'app-prism-environment-search',
  templateUrl: './prism-environment-search.component.html',
  styleUrls: ['./prism-environment-search.component.css']
})
export class PrismEnvironmentSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;
  defaultPageSize = 30;
  loadingGrid = false;
  pagerSubscription: Subscription;
  addPrismEnvironmentFeature = 'UI::PrismEnvironmentAdd';
  editPrismEnvironmentFeature = 'UI::PrismEnvironmentEdit';
  deletePrismEnvironmentFeature = 'UI::PrismEnvironmentDelete';
  searchFormGroup: FormGroup;
  dataSource: MatTableDataSource<PrismEnvironmentSearchResultModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [];
  searchString: string;
  searchCriteria: SearchCriteriaModel;
  sortOrder = '';
  newSortOrder = DEFAULT_SORT;
  
  constructor(private fb: FormBuilder,    
    private prismEnvironmentService: PrismEnvironmentService,
    private authorizationService: AuthorizationService,
    private editDialog: MatDialog,
    private messageService: MessageService,
    private deleteDialog: MatDialog
    ) { }

  ngOnInit() {
    this.initFormGroup();
    this.setupColumns();
    this.initialSearchLoad();
  }

  ngAfterViewInit() {
    this.paginator.pageSize = this.defaultPageSize;
    this.pagerSubscription = this.paginator.page
      .pipe(
        tap(() => this.loadSearchPage())
      )
      .subscribe();

    this.sort.sortChange.subscribe(data => {
      if (data['active'] !== 'ActionEdit' && data['active'] !== 'ActionDelete') {
        if (data['direction'] === '') {
          this.newSortOrder = '';
        } else {
          this.newSortOrder = `${(data['direction'] === 'desc') ? '-' : ''}${data['active']}`;
        }
        this.loadSearchPage();
      }
    });
  }

  ngOnDestroy() {
    if (this.pagerSubscription) {
      this.pagerSubscription.unsubscribe();
    }
  }

  initFormGroup() {
    this.searchFormGroup = this.fb.group({
      searchCriteria: new FormControl(''),
    });
  }
  
  setupColumns() {
    this.displayedColumns = [
      'PrismEnvironmentIdentifier',
      'PrismEnvironmentName',
      'PrismEnvironmentNameAnonymous',
      'PrismLPAR',
      'SpectrumSQLInstance',
      'PeerGroupName',
      'PeerGroupIncludeFlag',
      'IsGreenlight'
    ];
    if (this.hasDeletePrismEnvironmentFeature()) {
      this.displayedColumns.unshift('ActionDelete');
    }
    if (this.hasEditPrismEnvironmentFeature()) {
      this.displayedColumns.unshift('ActionEdit');
    }
  } 
 
  search() {
    this.paginator.pageIndex = 0;
    this.loadSearchPage();
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = this.defaultPageSize;
    this.initFormGroup();
    this.initialSearchLoad();
  }
 
  initialSearchLoad() {
    this.sortOrder = DEFAULT_SORT;
    this.newSortOrder = DEFAULT_SORT;
    this.sort.active = DEFAULT_SORT;
    this.sort.direction = 'asc';
    this.sort._stateChanges.next();

    this.searchString = '';
    this.loadSearchPageHelper(this.searchString, this.defaultPageSize, this.paginator.pageIndex, this.newSortOrder);
  }

  loadSearchPage() {
    if (this.newSortOrder !== this.sortOrder) {
      this.paginator.pageIndex = 0;
    }
    this.searchString = this.searchFormGroup.controls['searchCriteria'].value;
    this.loadSearchPageHelper(this.searchString, this.paginator.pageSize,
      (this.paginator.pageIndex * this.paginator.pageSize), this.newSortOrder);
  }

  loadSearchPageHelper(criteria: string, limit: number, offset: number, sortOrder: string) {

    if (this.sortOrder !== sortOrder) {
      this.sortOrder = sortOrder;
    }

    this.loadingGrid = true;
    this.searchCriteria = new SearchCriteriaModel();
    this.searchCriteria.criteria = this.searchString;
    this.prismEnvironmentService.search(this.searchCriteria, limit, offset, sortOrder)
      .subscribe(data => {
        this.dataSource = new MatTableDataSource<PrismEnvironmentSearchResultModel>(data.Items);

        this.dataSource.sortingDataAccessor = (myDataSource, sortHeaderId) => {
          if (typeof myDataSource[sortHeaderId] === 'string') {
            return myDataSource[sortHeaderId].toLocaleLowerCase();
          } else {
            return myDataSource[sortHeaderId];
          }
        };

        setTimeout(() => {
          this.paginator.length = data.TotalItemCount;
          this.dataSource.sort = this.sort;
        });
        this.loadingGrid = false;
      }, errorResponse => {
        this.messageService.HandleServerError(errorResponse);
        this.loadingGrid = false;
      });
  }

  add() {
    this.openPrismEnvironmentEditDialog(null);
  }

  edit(element: PrismEnvironmentSearchResultModel) {
    this.openPrismEnvironmentEditDialog(element);
  }

  openPrismEnvironmentEditDialog(element: PrismEnvironmentSearchResultModel): void {
    const dialogRef = this.editDialog.open(PrismEnvironmentEditDialogComponent,
      {
        width: '600px',
        data: { prismEnvironmentIdentifier: element ? element.PrismEnvironmentIdentifier : null }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.search();
      }
    });
  }

  openDeleteDialog(element: PrismEnvironmentSearchResultModel): void {
    const dialogRef = this.deleteDialog.open(ConfirmationDialogComponent,
      {
        width: '600px',
        data: {
          title: 'Confirm Delete', body: `Are you sure you want to delete Prism Environment:
          ${element.PrismEnvironmentIdentifier} :: ${element.PrismEnvironmentName} :: ${element.PrismEnvironmentNameAnonymous} ?`
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.prismEnvironmentService.delete(element.PrismEnvironmentIdentifier)
          .subscribe(data => {
            this.messageService.ShowDeletedSuccessfullyMessage();
            this.search();
          }, errorResponse => {
            this.messageService.HandleServerError(errorResponse);
          });
      }
    });
  }

  hasAddPrismEnvironmentFeature(): boolean {
    return this.authorizationService.hasFeature(this.addPrismEnvironmentFeature);
  }

  hasEditPrismEnvironmentFeature(): boolean {
    return this.authorizationService.hasFeature(this.editPrismEnvironmentFeature);
  }

  hasDeletePrismEnvironmentFeature(): boolean {
    return this.authorizationService.hasFeature(this.deletePrismEnvironmentFeature);
  }
}
