import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PharmacyRoutingModule } from './pharmacy-routing.module';
import { PharmacySearchComponent } from './components/pharmacy-search/pharmacy-search.component';
import { MaterialModule } from '../material-module/material-module';
import { CorporateEntityModule } from '../../modules/corporate-entity/corporate-entity.module';
import { PharmacyEditDialogComponent } from './components/pharmacy-edit-dialog/pharmacy-edit-dialog.component';
import { PharmacyUploadDialogComponent } from './components/pharmacy-upload-dialog/pharmacy-upload-dialog.component';
@NgModule({
  declarations: [PharmacySearchComponent, PharmacyEditDialogComponent, PharmacyUploadDialogComponent],
  entryComponents: [
    PharmacyEditDialogComponent,PharmacyUploadDialogComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    PharmacyRoutingModule,
    ReactiveFormsModule,
    MaterialModule
  ]
})
export class PharmacyModule { }
