import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthorizationService } from '../services/authorization.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authorization: AuthorizationService, private _cookieService: CookieService  ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.getCookie('id_token')) {
            return true;
        }
        this.authorization.getSecured(state.url);
        return false;
    }

    getCookie(key: string) {
        return this._cookieService.get(key);
    }
}
