import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LimitedResult, SearchCriteriaModel } from 'src/app/models';
import { PayerModel } from '../models';
import { PayerSearchResultModel } from '../models/payer-search-result-model';
import { PayerTypeService } from '../services/payer-type.service';
import { MessageService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class PayerService {

  baseUrl = environment.masterDataApiRoot + '/payers';

  constructor(private http: HttpClient) {
  }

  public search(searchCriteria: SearchCriteriaModel,
    limit: number,
    offset: number,
    sort: string): Observable<LimitedResult<PayerSearchResultModel>> {
    const url = `${this.baseUrl}/search?limit=${limit}&offset=${offset}&sort=${sort}`;

    return this.http.post<LimitedResult<PayerSearchResultModel>>(url, searchCriteria);
  }

  public get(id: number): Observable<PayerModel> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<PayerModel>(url);
  }

  public save(model: PayerModel): Observable<PayerModel> {
    if (model.PayerId) {
      return this.put(model);
    } else {
      return this.post(model);
    }
  }

  private post(model: PayerModel): Observable<PayerModel> {
    return this.http.post<PayerModel>(this.baseUrl, model);
  }

  private put(model: PayerModel): Observable<PayerModel> {
    const url = `${this.baseUrl}/${model.PayerId}`;
    return this.http.put<PayerModel>(url, model);
  }

  public delete(id: number): Observable<any> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete(url);
  }
}
