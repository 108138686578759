import { Component, OnInit, Inject } from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup,
  FormGroupDirective, NgForm, Validators
} from '@angular/forms';
import { PayerTypeModel } from '../../models';
import { PayerTypeService } from '../../services';
import { MessageService, AuthorizationService } from 'src/app/services';
import { MatDialogRef } from 'node_modules/@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-payer-type-edit-dialog',
  templateUrl: './payer-type-edit-dialog.component.html',
  styleUrls: ['./payer-type-edit-dialog.component.css']
})
export class PayerTypeEditDialogComponent implements OnInit {
  changed = false;
  editOnly: boolean;
  formGroup: FormGroup;
  model: PayerTypeModel;
  editMode = false;
  mode = '';

  constructor(private dialogRef: MatDialogRef<PayerTypeEditDialogComponent>,
    private fb: FormBuilder,
    private payerTypeService: PayerTypeService,
    private messageService: MessageService,
    private authorizationService: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    // Are we editing or adding
    this.editMode = this.data.payerTypeId != null ? true : false;

    if (this.editMode) {
      this.mode = 'Edit';
      // get model from API
      this.payerTypeService.get(this.data.payerTypeId)
        .subscribe(data => {
          this.model = data;
          this.loadForm();
        }, errorResponse => {
          this.messageService.HandleServerError(errorResponse);
        });
    } else {
      this.mode = 'Add';
      this.model = new PayerTypeModel();
      this.loadForm();
    }
  }

  loadForm() {
    this.formGroup = this.fb.group({
      payerTypeName: [this.model.PayerTypeName, Validators.compose([Validators.required, Validators.maxLength(100)])
      ]
    });
  }

  loadModelFromForm() {
    const controls = this.formGroup.controls;
    this.model.PayerTypeName = controls['payerTypeName'].value;
  }

  save() {
    this.loadModelFromForm();

    this.payerTypeService.save(this.model)
      .subscribe(data => {
        if (this.editMode) {
          this.model = data; // Saved and you can edit some more. The item is not cleared off the screen.
        } else {
          this.model = new PayerTypeModel(); // You can now add another one. we are still in Add mode.
        }
        this.changed = true;
        this.loadForm();
        this.messageService.ShowSavedSuccessfullyMessage();
      }, errorResponse => {
        this.messageService.HandleServerError(errorResponse);
      });
  }

  dismiss() {
    this.dialogRef.close(this.changed);
  }
}
