export class PharmacyModel {
        public PharmacyId: number;
       // [Required]
       // [StringLength(50)]
        public QualifiedProviderId: string;
        // [Required]
        // [StringLength(50)]
        public QualifiedProviderIdQualifier: string;
        // [Required]
        public EffectiveDateBegin: Date;
        public EffectiveDateEnd: Date;
        // [Required]
        // [StringLength(250)]
        public NameOrDescription: string;
        // [Required]
        // [StringLength(20)]
        public NationalProviderIdentifier: string;
        // [StringLength(100)]
        public NcpdpChainCode: string;
        // [Required]
        // [StringLength(20)]
        public NcpdpProviderId: string;
        // [StringLength(250)]
        public AddressStreet1: string;
        // [StringLength(250)]
        public AddressStreet2: string;
        // [StringLength(250)]
        public AddressCity: string;
        // [StringLength(2)]
        public AddressStateCode: string;
        // [StringLength(10)]
        public AddressPostalCode: string;
        // [StringLength(30)]
        public PhoneNumber: string;
        public DaysOfOperationSundayFlag: boolean;
        public DaysOfOperationMondayFlag: boolean;
        public DaysOfOperationTuesdayFlag: boolean;
        public DaysOfOperationWednesdayFlag: boolean;
        public DaysOfOperationThursdayFlag: boolean;
        public DaysOfOperationFridayFlag: boolean;
        public DaysOfOperationSaturdayFlag: boolean;
        // [Required]
        public CorporateEntityIdentifier: number;
        // [StringLength(250)]
        public CorporateGroupOrDivision: string;
        // [StringLength(50)]
        public CorporateLocationNumber: string;
        // [Required]
        // [StringLength(50)]
        public DataUsageCode: string;
        public DemoFlag: boolean;
        // [Required]
        // [StringLength(50)]
        public MarketCategory: string;
        // [StringLength(250)]
        public PeerGroup: string;
        // [StringLength(50)]
        public PharmacyType: string;
        // [StringLength(250)]
        public RetailBanner: string;
        // [StringLength(50)]
        public HiveClientId: string;
}
