import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthorizationService } from './services';
import { DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import * as routes from './routes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {
  FooterComponent,
  HeaderComponent
} from './components';
import { AuthGuard, FeatureGuard } from './guards';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { SvgIconDirective } from './directives/svg-icon.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MaterialModule } from '../app/modules/material-module/material-module';

import { CorporateEntityModule } from './modules/corporate-entity/corporate-entity.module';
import { PayerModule } from './modules/payer/payer.module';
import { PharmacyModule } from './modules/pharmacy/pharmacy.module';
import { PrismEnvironmentModule } from './modules/prism-environment/prism-environment.module';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { HealthcheckComponent } from './components/healthcheck/healthcheck.component';



@NgModule({
  entryComponents: [
    ConfirmationDialogComponent,
    InfoDialogComponent
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    LandingPageComponent,
    SvgIconDirective,
    ConfirmationDialogComponent,
    InfoDialogComponent,
    HealthcheckComponent
  ],
  imports: [
    BrowserModule,
    CorporateEntityModule,
    FormsModule,
    PayerModule,
    PharmacyModule,
    PrismEnvironmentModule,
    HttpClientModule,
    MaterialModule,
    RouterModule.forRoot(routes.appRoutes,
      {
        enableTracing: !environment.production,
        onSameUrlNavigation: 'reload',
        useHash: true,
      }),
    ReactiveFormsModule,
  ],
  providers: [
    CookieService,
    AuthGuard,
    FeatureGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    AuthorizationService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
