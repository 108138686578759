import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CorporateEntityModel } from '../../models';
import { MatDialogRef } from 'node_modules/@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MessageService, AuthorizationService } from 'src/app/services';
import { ErrorStateMatcher } from '@angular/material/core';
import { CorporateEntityService } from '../../services/corporate-entity.service';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { DataUsageCodeService } from 'src/app/services/data-usage-code.service';
import { ErrorStateMatcherDirty } from 'src/app/models';

@Component({
  selector: 'app-corporate-entity-edit-dialog',
  templateUrl: './corporate-entity-edit-dialog.component.html',
  styleUrls: ['./corporate-entity-edit-dialog.component.css']
})

export class CorporateEntityEditDialogComponent implements OnInit {
  changed = false;
  editOnly: boolean;
  formGroup: FormGroup;
  corporateEntityModel: CorporateEntityModel;
  editMode = false;
  mode = '';
  matcher = new ErrorStateMatcherDirty();
  corporateEntityIdentifier: number;

  constructor(private dialogRef: MatDialogRef<CorporateEntityEditDialogComponent>,
    private fb: FormBuilder,
    private corporateEntityService: CorporateEntityService,
    private messageService: MessageService,
    private dataUsageCodeService: DataUsageCodeService,
    private authorizationService: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) private data: any) {
  }

  ngOnInit() {
    // Are we editing or adding
    this.editMode = this.data.corporateEntityIdentifier !== null && this.data.corporateEntityIdentifier !== undefined ? true : false;

    if (this.editMode) {
      this.mode = 'Edit';

      this.corporateEntityIdentifier = this.data.corporateEntityIdentifier;
      this.corporateEntityService.get(this.corporateEntityIdentifier)
        .subscribe(data => {
          this.corporateEntityModel = data;
          this.loadForm();
        }, errorResponse => {
          this.messageService.HandleServerError(errorResponse);

        });
    } else {
      this.mode = 'Add';
      this.corporateEntityModel = new CorporateEntityModel();
      this.loadForm();
    }
  }

  loadForm() {
    this.formGroup = this.fb.group({
      corporateEntityIdentifier: [this.corporateEntityModel.CorporateEntityIdentifier, Validators.required],
      corporateEntityName: [this.corporateEntityModel.CorporateEntityName, Validators.compose([Validators.required, Validators.maxLength(250)])],
      corporateEntityNameAnonymous: [this.corporateEntityModel.CorporateEntityNameAnonymous],
      peerGroupName: [this.corporateEntityModel.PeerGroupName, Validators.compose([Validators.maxLength(200)])],
      peerGroupIncludeFlag: [this.corporateEntityModel.PeerGroupIncludeFlag]
    });
  }

  loadModelFromForm() {
    const controls = this.formGroup.controls;
    this.corporateEntityModel.CorporateEntityIdentifier = controls['corporateEntityIdentifier'].value;
    this.corporateEntityModel.CorporateEntityName = controls['corporateEntityName'].value;
    this.corporateEntityModel.CorporateEntityNameAnonymous = controls['corporateEntityNameAnonymous'].value;
    this.corporateEntityModel.PeerGroupName = controls['peerGroupName'].value;
    this.corporateEntityModel.PeerGroupIncludeFlag = controls['peerGroupIncludeFlag'].value;
  }

  save() {
    this.loadModelFromForm();

    this.corporateEntityService.save(!this.editMode, this.corporateEntityModel)
      .subscribe(data => {
        if (this.editMode) {
          this.corporateEntityModel = data; // Saved and you can edit some more. The item is not cleared off the screen.
        } else {
          this.corporateEntityModel = new CorporateEntityModel(); // You can now add another one. we are still in Add mode.
        }
        this.changed = true;
        this.loadForm();
        this.messageService.ShowSavedSuccessfullyMessage();

      }, errorResponse => {
        this.messageService.HandleServerError(errorResponse);
      });
  }

  dismiss() {
    this.dialogRef.close(this.changed);
  }

}
