import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, FeatureGuard } from 'src/app/guards';
import { PayerTypeSearchComponent } from './components/payer-type-search/payer-type-search.component';
import { PayerSearchComponent } from './components/payer-search/payer-search.component';
const routes: Routes = [
    {
        path: 'payers',
        component: PayerSearchComponent,
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'UI::PayerList' }
    },
    {
        path: 'payertypes',
        component: PayerTypeSearchComponent,
        canActivate: [AuthGuard, FeatureGuard],
        data: { feature: 'UI::PayerTypeList' }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PayerRoutingModule { }
