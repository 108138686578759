<h2>{{mode}} Prism Environment</h2>
<mat-dialog-content>
  <div *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <div class="form-container">
        <mat-form-field *ngIf="mode === 'Edit'">
          <mat-label>PrismEnvironmentIdentifier</mat-label>
          <input matInput placeholder="PrismEnvironmentIdentifier (numbers only)" type="number" formControlName="prismEnvironmentIdentifier" required readonly>
        </mat-form-field>
        <mat-form-field *ngIf="mode !== 'Edit'">
          <mat-label>PrismEnvironmentIdentifier</mat-label>
          <input matInput placeholder="PrismEnvironmentIdentifier (numbers only)" type="number" formControlName="prismEnvironmentIdentifier" required>
          <mat-error *ngIf="formGroup.controls.prismEnvironmentIdentifier.errors?.required">Required.</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>PrismEnvironmentName</mat-label>
          <input matInput placeholder="PrismEnvironmentName" [errorStateMatcher]="matcher" formControlName="prismEnvironmentName" required>
          <mat-error *ngIf="formGroup.controls.prismEnvironmentName.errors?.required">Required.</mat-error>
          <mat-error *ngIf="formGroup.controls.prismEnvironmentName.errors?.maxlength">MaxLength {{formGroup.controls.prismEnvironmentName.errors?.maxlength['requiredLength']}} has been exceeded.</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="mode === 'Edit'">
          <mat-label>PrismEnvironmentNameAnonymous</mat-label>
          <input matInput readonly placeholder="PrismEnvironmentNameAnonymous" [errorStateMatcher]="matcher" formControlName="prismEnvironmentNameAnonymous">
        </mat-form-field>
        <mat-form-field>
          <mat-label>PrismLPAR</mat-label>
          <mat-select name="PrismLPAR"  formControlName="prismLPAR"
              placeholder="Select" required>
              <mat-option *ngFor="let item of prismLPARList" [value]="item.key">
                  {{item.value}}
              </mat-option>
          </mat-select>
                    <mat-error *ngIf="formGroup.controls.spectrumSQLInstance.errors?.required">Required.</mat-error>
        </mat-form-field> 
        <mat-form-field>
          <mat-label>SpectrumSQLInstance</mat-label>
          <mat-select name="SpectrumSQLInstance"  formControlName="spectrumSQLInstance"
              placeholder="Select" required>
              <mat-option *ngFor="let item of spectrumSQLInstanceList" [value]="item.key">
                  {{item.value}}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.controls.spectrumSQLInstance.errors?.required">Required.</mat-error>
        </mat-form-field> 
        <mat-form-field>
          <mat-label>PeerGroupName</mat-label>
          <input matInput placeholder="PeerGroupName" [errorStateMatcher]="matcher" formControlName="peerGroupName" required>
          <mat-error *ngIf="formGroup.controls.peerGroupName.errors?.required">Required.</mat-error>
          <mat-error *ngIf="formGroup.controls.peerGroupName.errors?.maxlength">MaxLength {{formGroup.controls.peerGroupName.errors?.maxlength['requiredLength']}} has been exceeded.</mat-error>
        </mat-form-field>
          <mat-checkbox formControlName="peerGroupIncludeFlag">PeerGroupIncludeFlag</mat-checkbox>
          <mat-checkbox formControlName="isGreenlight">IsGreenlight</mat-checkbox>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="formGroup" align="end" class='inm-spaced--compact'>
  <button class="inm-button--action" (click)="save()" [disabled]="!formGroup.dirty || !formGroup.valid">
    Save
  </button>
  <button *ngIf="!formGroup.dirty" class="inm-button" (click)="dismiss()">
    Close
  </button>
  <button *ngIf="formGroup.dirty" class="inm-button" (click)="dismiss()">
    Cancel
  </button>
</mat-dialog-actions>
