import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from 'node_modules/@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent implements OnInit {
  title = '';
  body = '';
  constructor(private dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
    if (this.data) {
      if (this.data.title) {
        this.title = this.data.title;
      }
      if (this.data.body) {
        this.body = this.data.body;
      }
    }
  }

  dismiss() {
    this.dialogRef.close(false);
  }
}