export class PharmacySearchResultItem {
    public PharmacyId: number;
    public QualifiedProviderId: string;
    public QualifiedProviderIdQualifier: string;
    public EffectiveDateBegin: Date;
    public EffectiveDateEnd: Date;
    public NameOrDescription: string;
    public NationalProviderIdentifier: string;
    public NcpdpChainCode: string;
    public NcpdpProviderId: string;
    public AddressStreet1: string;
    public AddressStreet2: string;
    public AddressCity: string;
    public AddressStateCode: string;
    public AddressPostalCode: string;
    public PhoneNumber: string;
    public DaysOfOperationSundayFlag: boolean;
    public DaysOfOperationMondayFlag: boolean;
    public DaysOfOperationTuesdayFlag: boolean;
    public DaysOfOperationWednesdayFlag: boolean;
    public DaysOfOperationThursdayFlag: boolean;
    public DaysOfOperationFridayFlag: boolean;
    public DaysOfOperationSaturdayFlag: boolean;
    public CorporateEntityIdentifier: number;
    public CorporateGroupOrDivision: string;
    public CorporateLocationNumber: string;
    public DataUsageCode: string;
    public DemoFlag: boolean;
    public MarketCategory: string;
    public PeerGroup: string;
    public PharmacyType: string;
    public RetailBanner: string;
    public HiveClientId: string;
}
