import { Injectable } from '@angular/core';
import * as toastr from 'toastr';
@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() {

  }

  // If error is a 400 then show the messages from the error. Our api should be returning a value safe to show.
  // Else if error is 500, then return a very generic server error message and DON'T show the server's message.
  HandleServerError(errorResponse) {

    if (errorResponse['status'] === 400) {
      if (errorResponse['error'] && errorResponse['error']['Error']) {
        toastr.error(errorResponse['error']['Error'].toString());
      }

      if (errorResponse['error'] && errorResponse['error']['Content']) {
        errorResponse['error']['Content'].forEach(element => {
          toastr.error(`${element['PropertyName']} : ${element['PropertyError']}`, null, { timeOut: 9500 });

        });
      }
    } else if (errorResponse['status'] === 404) {
      this.ShowNotFoundErrorMessage();
    } else {
      this.ShowServerErrorTryLater();
    }
  }

  // Common error messages
  ShowServerErrorTryLater() {
    const message = 'There was a server error. Please verify your request and try again.';
    toastr.error(message);
  }

  // Successes
  ShowSavedSuccessfullyMessage() {
    const message = 'Successfully saved.';
    toastr.success(message);
  }

  ShowDeletedSuccessfullyMessage() {
    const message = 'Deleted successfully.';
    toastr.success(message);
  }

  ShowNotFoundErrorMessage() {
    const message = 'The item you requested was not found.';
    toastr.error(message);
  }
}

