import { Component, AfterViewInit } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthorizationService } from './services/authorization.service';
import * as toastr from 'toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = environment.applicationDisplayName;
  sections: string = JSON.stringify([{
    name: 'MASTER DATA',
    path: 'masterdata',
    feature: 'UI::MenuItemMasterData',
    subSection: null,
  },
  {
    name: 'PHARMACY',
    path: 'pharmacy',
    feature: 'UI::PharmacyMenuItem',
    subSection: null,
  },
  {
    name: 'CORPORATE ENTITY',
    path: 'corporateentities',
    feature: 'UI::CorporateEntityMenuItem',
    subSection: null,
  },
  {
    name: 'PRISM ENVIRONMENT',
    path: 'prismenvironments',
    feature: 'UI::PrismEnvironmentMenuItem',
    subSection: null,
  },
  {
    name: 'PAYER',
    path: 'payers',
    feature: 'UI::PayerMenuItem',
    subSection: null,
  },
  {
    name: 'PAYER TYPE',
    path: 'payertypes',
    feature: 'UI::PayerTypeMenuItem',
    subSection: null,
  }
  ]);
  constructor(// private appInsightsService: AppInsightsService,
    private authorizationService: AuthorizationService) {
    toastr.options = {
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '1500',
      extendedTimeOut: '500',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
      positionClass: 'toast-top-right'
    };

    // this.appInsightsService.logPageView('Application Initialized');
  }
  ngAfterViewInit() {
  }
  routeChanged() {
    if (this.authorizationService.HealthcareTopBar) {
      this.authorizationService.HealthcareTopBar.notifyActivity();
    }
  }
}
