import { Injectable } from '@angular/core';
import { KeyValuePairModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MarketCategoryService {
  marketCategories: KeyValuePairModel[];

  constructor() { }


  public getMarketCategories(): KeyValuePairModel[] {
    if (!this.marketCategories) {
      this.marketCategories = [];
      this.marketCategories.push(new KeyValuePairModel('Large Retailer', 'Large Retailer'));
      this.marketCategories.push(new KeyValuePairModel('Indy/Small Chain', 'Indy/Small Chain'));
      this.marketCategories.push(new KeyValuePairModel('Regional Retailer', 'Regional Retailer'));
      this.marketCategories.push(new KeyValuePairModel('Health System', 'Health System'));
      this.marketCategories.push(new KeyValuePairModel('Other', 'Other'));
    }
    return this.marketCategories;
  }
}
