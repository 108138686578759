import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, FeatureGuard } from 'src/app/guards';
import { PrismEnvironmentSearchComponent } from './components/prism-environment-search/prism-environment-search.component';
const routes: Routes = [
  {
    path: 'prismenvironments',
    component: PrismEnvironmentSearchComponent,
    canActivate: [AuthGuard, FeatureGuard],
    data: {feature: 'UI::PrismEnvironmentList'}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrismEnvironmentRoutingModule { }
