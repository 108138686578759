<h2>{{mode}} Payer Type</h2>
<mat-dialog-content>
  <div *ngIf="formGroup">
    <form [formGroup]="formGroup">
      <div class="form-container">
        <mat-form-field>
          <input formControlName="payerTypeName" matInput placeholder="Payer Type Name" required>
          <mat-error *ngIf="formGroup.controls.payerTypeName.errors?.maxlength">MaxLength
            {{formGroup.controls.payerTypeName.errors?.maxlength['requiredLength']}} has
            been exceeded.
          </mat-error>
          <mat-error *ngIf="formGroup.controls.payerTypeName.errors?.required">Required.
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="formGroup" align="end" class='inm-spaced--compact'>
  <button class="inm-button--action" (click)="save()" [disabled]="!formGroup.dirty || !formGroup.valid">
    Save
  </button>
  <button *ngIf="!formGroup.dirty" class="inm-button" (click)="dismiss()">
    Close
  </button>
  <button *ngIf="formGroup.dirty" class="inm-button" (click)="dismiss()">
    Cancel
  </button>
</mat-dialog-actions>