import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayerModel } from '../../models';
import { PayerService, PayerTypeService } from '../../services';
import { MessageService, AuthorizationService } from 'src/app/services';
import { MatDialogRef } from 'node_modules/@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material';
import { KeyValuePairModel } from 'src/app/models';

@Component({
  selector: 'app-payer-edit-dialog',
  templateUrl: './payer-edit-dialog.component.html',
  styleUrls: ['./payer-edit-dialog.component.css']
})
export class PayerEditDialogComponent implements OnInit {
  changed = false;
  editOnly: boolean;
  formGroup: FormGroup;
  model: PayerModel;
  editMode = false;
  mode = '';
  payerTypes: KeyValuePairModel[];

  constructor(private dialogRef: MatDialogRef<PayerEditDialogComponent>,
    private fb: FormBuilder,
    private payerService: PayerService,
    private payerTypeService: PayerTypeService,
    private messageService: MessageService,
    private authorizationService: AuthorizationService,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    this.loadPayerTypes(); // for selectList control

    // Are we editing or adding
    this.editMode = this.data.payerId != null ? true : false;

    if (this.editMode) {
      this.mode = 'Edit';
      // get model from API
      this.payerService.get(this.data.payerId)
        .subscribe(data => {
          this.model = data;
          this.loadForm();
        }, errorResponse => {
          this.messageService.HandleServerError(errorResponse);
        });
    } else {
      this.mode = 'Add';
      this.model = this.createDefaultModel();
      this.loadForm();
    }
  }

  createDefaultModel() {
    const newModel = new PayerModel();
    newModel.Bin = '';
    newModel.Pcn = '';
    newModel.GroupNumber = '';
    return newModel;
  }
  loadPayerTypes() {
    this.payerTypeService.getPayerTypeList()
      .subscribe(data => {
        this.payerTypes = data;
      });
  }

  loadForm() {
    this.formGroup = this.fb.group({
      PayerId: [this.model.PayerId], // no validation because we make it read only
      Bin: [this.model.Bin, Validators.maxLength(10)],
      Pcn: [this.model.Pcn, Validators.maxLength(20)],
      GroupNumber: [this.model.GroupNumber, Validators.maxLength(20)],
      InmarPayerIdentifier: [this.model.InmarPayerIdentifier],
      GroupSettlement: [this.model.GroupSettlement, Validators.maxLength(100)],
      GroupContracting: [this.model.GroupContracting, Validators.maxLength(100)],
      GroupMacAppeals: [this.model.GroupMacAppeals, Validators.maxLength(100)],
      PayerName: [this.model.PayerName, Validators.compose([Validators.required, Validators.maxLength(100)])],
      PayerTypeId: [this.model.PayerTypeId ? this.model.PayerTypeId.toString() : '', Validators.required],
      Notes: [this.model.Notes]
    });

  }

  loadModelFromForm() {
    const controls = this.formGroup.controls;
    this.model.PayerId = controls['PayerId'].value;
    this.model.Bin = controls['Bin'].value;
    this.model.Pcn = controls['Pcn'].value;
    this.model.GroupNumber = controls['GroupNumber'].value;
    this.model.InmarPayerIdentifier = controls['InmarPayerIdentifier'].value;
    this.model.GroupSettlement = controls['GroupSettlement'].value;
    this.model.GroupContracting = controls['GroupContracting'].value;
    this.model.GroupMacAppeals = controls['GroupMacAppeals'].value;
    this.model.PayerName = controls['PayerName'].value;
    this.model.PayerTypeId = +controls['PayerTypeId'].value;
    this.model.Notes = controls['Notes'].value;
  }

  save() {
    this.loadModelFromForm();

    this.payerService.save(this.model)
      .subscribe(data => {
        if (this.editMode) {
          this.model = data; // Saved and you can edit some more. The item is not cleared off the screen.
        } else {
          this.model = this.createDefaultModel();
        }
        this.changed = true;
        this.loadForm();
        this.messageService.ShowSavedSuccessfullyMessage();
      }, errorResponse => {
        this.messageService.HandleServerError(errorResponse);
      });
  }

  dismiss() {
    this.dialogRef.close(this.changed);
  }

  leftInmarPayerIdentifierField(event: any, defaultValue: string) {

    // change whitespace to defaultValue of the control we just left
    if (event.srcElement.value.trim() === '') {
      event.srcElement.value = defaultValue;
    }
    // Create InmarPayerIdentifier
    // Trim each field. If it doesn't have any characters then change it to '*'.
    const bin = this.formGroup.controls['Bin'].value && this.formGroup.controls['Bin'].value.trim() !== ''
      ? this.formGroup.controls['Bin'].value.trim()
      : '*';

    const pcn = this.formGroup.controls['Pcn'].value && this.formGroup.controls['Pcn'].value.trim() !== ''
      ? this.formGroup.controls['Pcn'].value.trim()
      : '';

    const groupNumber = this.formGroup.controls['GroupNumber'].value && this.formGroup.controls['GroupNumber'].value.trim() !== ''
      ? this.formGroup.controls['GroupNumber'].value.trim()
      : '';

    // Load the computed value into the InmarPayerIdentifier field
    this.formGroup.controls['InmarPayerIdentifier']
      .setValue(`${bin}|${pcn}|${groupNumber}`);

    // now run validation on the value we just set
    this.validateInmarPayerIdentifierCombo(bin, pcn, groupNumber);
  }

  validateInmarPayerIdentifierCombo(bin: string, pcn: string, groupNumber: string) {
    const star = '*';
    if ((bin !== star && pcn !== star && groupNumber !== star) ||
      (bin !== star && pcn !== star && groupNumber === star) ||
      (bin !== star && pcn === star && groupNumber === star) ||
      (bin === star && pcn === star && groupNumber === star)) {

      this.formGroup.controls['InmarPayerIdentifier'].setErrors(null);
    } else {
      this.formGroup.controls['InmarPayerIdentifier'].setErrors({ invalidCombination: true });
    }
  }
}
