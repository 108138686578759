import { Client } from './client';
import { Feature } from './feature';

export class User {
    UserId: number;
    UserName: string;
    EmailAddress: string;
    IsRegistered: boolean;
    IsActive: boolean;
    Clients: Client[];
    Features: Feature[];
    UserProfileSettings: string;
}
