import { Pipe, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchCriteriaModel, KeyValuePairModel, LimitedResult } from 'src/app/models';
import { map } from 'rxjs/operators';
import { PrismEnvironmentSearchResultModel } from '../models/prism-environment-search-result-model';
import { PrismEnvironmentModel } from '../models/prism-environment-model';

@Injectable({
  providedIn: 'root'
})
export class PrismEnvironmentService {
  peEndpoint = environment.masterDataApiRoot + '/prismenvironments';

  constructor(private http: HttpClient) {
  }

  // For populating SelectList
  public getCorporateEntityList(): Observable<KeyValuePairModel[]> {
    const searchCriteria: SearchCriteriaModel = new SearchCriteriaModel();
    searchCriteria.criteria = '';
    const limit = 9999;

    return this.http.post<PrismEnvironmentSearchResultModel[]>(`${this.peEndpoint}/search/?limit=${limit}`, searchCriteria)
      .pipe(
        map(res => res['Items'].map(item => new KeyValuePairModel(item.PrismEnvironmentIdentifier.toString(),
          `${item.PrismEnvironmentIdentifier} :: ${item.PrismEnvironmentName} :: ${item.PrismEnvironmentNameAnonymous} ::
           ${item.PrismLPAR} :: ${item.SpectrumSQLInstance} :: ${item.PeerGroupName}`))));
  }

  public search(searchCriteria: SearchCriteriaModel,
    limit: number,
    offset: number,
    sort: string): Observable<LimitedResult<PrismEnvironmentSearchResultModel>> {
    const url = `${this.peEndpoint}/search?limit=${limit}&offset=${offset}&sort=${sort}`;
    return this.http.post<LimitedResult<PrismEnvironmentSearchResultModel>>(url, searchCriteria);
  }

  public get(PrismEnvironmentIdentifier: number): Observable<PrismEnvironmentModel> {
    const url = `${this.peEndpoint}/${PrismEnvironmentIdentifier}`;
    return this.http.get<PrismEnvironmentModel>(url);
  }

  public save(insert: boolean, prismEnvironmentModel: PrismEnvironmentModel): Observable<PrismEnvironmentModel> {
    if (insert) {
      return this.post(prismEnvironmentModel);
    } else {
      return this.put(prismEnvironmentModel);
    }
  }

  private post(prismEnvironmentModel: PrismEnvironmentModel): Observable<PrismEnvironmentModel> {
    return this.http.post<PrismEnvironmentModel>(this.peEndpoint, prismEnvironmentModel);
  }

  private put(prismEnvironmentModel: PrismEnvironmentModel): Observable<PrismEnvironmentModel> {
    const url = `${this.peEndpoint}/${prismEnvironmentModel.PrismEnvironmentIdentifier}`;
    return this.http.put<PrismEnvironmentModel>(url, prismEnvironmentModel);
  }

  public delete(id: number): Observable<any> {
    const url = `${this.peEndpoint}/${id}`;
    return this.http.delete(url);
  }
}
