<h2>{{mode}} Pharmacy</h2>
<mat-dialog-content>
    <div *ngIf="pharmFormGroup">
        <form [formGroup]="pharmFormGroup">
            <div class="form-container">
                <mat-form-field *ngIf="mode === 'Edit'">
                    <input formControlName="PharmacyId" matInput placeholder="Pharmacy Id" readonly>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="QualifiedProviderId" matInput required
                        placeholder="QualifiedProviderId">
                    <mat-error *ngIf="pharmFormGroup.controls.QualifiedProviderId.errors?.required">Required.
                    </mat-error>
                    <mat-error *ngIf="pharmFormGroup.controls.QualifiedProviderId.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.QualifiedProviderId.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="QualifiedProviderIdQualifier" matInput
                        required placeholder="QualifiedProviderIdQualifier">
                    <mat-error *ngIf="pharmFormGroup.controls.QualifiedProviderIdQualifier.errors?.required">Required.
                    </mat-error>
                    <mat-error *ngIf="pharmFormGroup.controls.QualifiedProviderIdQualifier.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.QualifiedProviderIdQualifier.errors?.maxlength['requiredLength']}} has
                        been exceeded.
                    </mat-error>
                </mat-form-field>
                <div class="flex-row">
                    <div class="form-container">
                        <mat-form-field>
                            <input matInput [matDatepicker]="EffectiveDateBeginPicker" required
                                formControlName="EffectiveDateBegin" placeholder="EffectiveDateBegin">
                            <mat-datepicker-toggle matSuffix [for]="EffectiveDateBeginPicker"></mat-datepicker-toggle>
                            <mat-datepicker #EffectiveDateBeginPicker></mat-datepicker>
                            <mat-error *ngIf="pharmFormGroup.controls.EffectiveDateBegin.errors?.required">Required.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput [matDatepicker]="EffectiveDateEndPicker" formControlName="EffectiveDateEnd"
                                placeholder="EffectiveDateEnd">
                            <mat-datepicker-toggle matSuffix [for]="EffectiveDateEndPicker"></mat-datepicker-toggle>
                            <mat-datepicker #EffectiveDateEndPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div id='rightErrorBox'>
                        <label class="mat-error"
                            *ngIf="pharmFormGroup.errors">{{ pharmFormGroup.errors?.dates | json }}</label>
                    </div>
                </div>

                <mat-form-field>
                    <input formControlName="NameOrDescription" matInput required placeholder="NameOrDescription">
                    <mat-error *ngIf="pharmFormGroup.controls.NameOrDescription.errors?.required">Required.</mat-error>
                    <mat-error *ngIf="pharmFormGroup.controls.NameOrDescription.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.NameOrDescription.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" required formControlName="NationalProviderIdentifier" matInput
                        placeholder="NationalProviderIdentifier">
                    <mat-error *ngIf="pharmFormGroup.controls.NationalProviderIdentifier.errors?.required">Required.
                    </mat-error>
                    <mat-error *ngIf="pharmFormGroup.controls.NationalProviderIdentifier.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.NationalProviderIdentifier.errors?.maxlength['requiredLength']}} has
                        been exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="NcpdpChainCode" matInput
                        placeholder="NcpdpChainCode">
                    <mat-error *ngIf="pharmFormGroup.controls.NcpdpChainCode.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.NcpdpChainCode.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="NcpdpProviderId" matInput required
                        placeholder="NcpdpProviderId">
                    <mat-error *ngIf="pharmFormGroup.controls.NcpdpProviderId.errors?.required">Required.</mat-error>
                    <mat-error *ngIf="pharmFormGroup.controls.NcpdpProviderId.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.NcpdpProviderId.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="AddressStreet1" matInput
                        placeholder="AddressStreet1">
                    <mat-error *ngIf="pharmFormGroup.controls.AddressStreet1.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.AddressStreet1.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="AddressStreet2" matInput
                        placeholder="AddressStreet2">
                    <mat-error *ngIf="pharmFormGroup.controls.AddressStreet2.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.AddressStreet2.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="AddressCity" matInput
                        placeholder="AddressCity">
                    <mat-error *ngIf="pharmFormGroup.controls.AddressCity.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.AddressCity.errors?.maxlength['requiredLength']}} has been exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>AddressStateCode</mat-label>
                    <mat-select name="AddressStateCode"  formControlName="AddressStateCode"
                        placeholder="Select">
                        <mat-option *ngFor="let item of stateList" [value]="item.key">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="AddressPostalCode" matInput
                        placeholder="AddressPostalCode">
                    <mat-error *ngIf="pharmFormGroup.controls.AddressPostalCode.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.AddressPostalCode.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="PhoneNumber" matInput
                        placeholder="PhoneNumber">
                    <mat-error *ngIf="pharmFormGroup.controls.PhoneNumber.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.PhoneNumber.errors?.maxlength['requiredLength']}} has been exceeded.
                    </mat-error>
                </mat-form-field>

                <mat-checkbox formControlName="DaysOfOperationSundayFlag">DaysOfOperationSundayFlag</mat-checkbox>

                <mat-checkbox formControlName="DaysOfOperationMondayFlag">DaysOfOperationMondayFlag</mat-checkbox>

                <mat-checkbox formControlName="DaysOfOperationTuesdayFlag">DaysOfOperationTuesdayFlag</mat-checkbox>

                <mat-checkbox formControlName="DaysOfOperationWednesdayFlag">DaysOfOperationWednesdayFlag</mat-checkbox>

                <mat-checkbox formControlName="DaysOfOperationThursdayFlag">DaysOfOperationThursdayFlag</mat-checkbox>

                <mat-checkbox formControlName="DaysOfOperationFridayFlag">DaysOfOperationFridayFlag</mat-checkbox>

                <mat-checkbox formControlName="DaysOfOperationSaturdayFlag">DaysOfOperationSaturdayFlag</mat-checkbox>

                <mat-form-field>
                    <mat-label>CorporateEntityIdentifier</mat-label>
                    <mat-select name="CorporateEntityIdentifier" formControlName="CorporateEntityIdentifier"
                        (selectionChange)="changedCorporateEntityIdentifier($event.value)" placeholder="Select"
                        required>
                        <mat-option *ngFor="let item of corporateEntityList" [value]="item.key">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="pharmFormGroup.controls.CorporateEntityIdentifier.errors?.required">Required.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="CorporateGroupOrDivision" matInput
                        placeholder="CorporateGroupOrDivision">
                    <mat-error *ngIf="pharmFormGroup.controls.CorporateGroupOrDivision.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.CorporateGroupOrDivision.errors?.maxlength['requiredLength']}} has
                        been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="CorporateLocationNumber" matInput
                        placeholder="CorporateLocationNumber">
                    <mat-error *ngIf="pharmFormGroup.controls.CorporateLocationNumber.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.CorporateLocationNumber.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>DataUsageCode</mat-label>
                    <mat-select name="DataUsageCode" required formControlName="DataUsageCode" placeholder="Select">
                        <mat-option *ngFor="let item of dataUsageTypes" [value]="item.key">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="pharmFormGroup.controls.DataUsageCode.errors?.required">Required.</mat-error>
                    <mat-error *ngIf="pharmFormGroup.controls.DataUsageCode.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.DataUsageCode.errors?.maxlength['requiredLength']}} has been exceeded.
                    </mat-error>
                </mat-form-field>

                <mat-checkbox formControlName="DemoFlag">DemoFlag</mat-checkbox>

                <mat-form-field>
                    <mat-label>MarketCategory</mat-label>
                    <mat-select name="MarketCategory" formControlName="MarketCategory" placeholder="Select" required>
                        <mat-option *ngFor="let item of marketCategoryList" [value]="item.key">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="pharmFormGroup.controls.MarketCategory.errors?.required">Required.</mat-error>
                    <mat-error *ngIf="pharmFormGroup.controls.MarketCategory.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.MarketCategory.errors?.maxlength['requiredLength']}} has been
                        exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="PeerGroup" matInput placeholder="PeerGroup">
                    <mat-error *ngIf="pharmFormGroup.controls.PeerGroup.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.PeerGroup.errors?.maxlength['requiredLength']}} has been exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>PharmacyType</mat-label>
                    <mat-select name="PharmacyType" formControlName="PharmacyType" placeholder="Select">
                        <mat-option *ngFor="let item of pharmacyTypeList" [value]="item.key">
                            {{item.value}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="pharmFormGroup.controls.PharmacyType.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.PharmacyType.errors?.maxlength['requiredLength']}} has been exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="RetailBanner" matInput
                        placeholder="RetailBanner">
                    <mat-error *ngIf="pharmFormGroup.controls.RetailBanner.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.RetailBanner.errors?.maxlength['requiredLength']}} has been exceeded.
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <input [errorStateMatcher]="matcher" formControlName="HiveClientId" matInput
                        placeholder="HiveClientId">
                    <mat-error *ngIf="pharmFormGroup.controls.HiveClientId.errors?.maxlength">MaxLength
                        {{pharmFormGroup.controls.HiveClientId.errors?.maxlength['requiredLength']}} has been exceeded.
                    </mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="pharmFormGroup" align="end" class='inm-spaced--compact'>
    <button class="inm-button--action" (click)="save()" [disabled]="!pharmFormGroup.dirty || !pharmFormGroup.valid">
        Save
    </button>
    <button *ngIf="!pharmFormGroup.dirty" class="inm-button" (click)="dismiss()">
        Close
    </button>
    <button *ngIf="pharmFormGroup.dirty" class="inm-button" (click)="dismiss()">
        Cancel
    </button>
</mat-dialog-actions>