import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module/material-module';
import { ReactiveFormsModule } from '@angular/forms';
import { PayerRoutingModule } from './payer-routing.module';
import { PayerTypeSearchComponent } from './components/payer-type-search/payer-type-search.component';
import { PayerTypeEditDialogComponent } from './components/payer-type-edit-dialog/payer-type-edit-dialog.component';
import { PayerSearchComponent } from './components/payer-search/payer-search.component';
import { PayerEditDialogComponent } from './components/payer-edit-dialog/payer-edit-dialog.component';

@NgModule({
  declarations: [PayerTypeSearchComponent, PayerTypeEditDialogComponent, PayerSearchComponent, PayerEditDialogComponent],
  entryComponents: [
    PayerTypeEditDialogComponent,
    PayerEditDialogComponent
  ],
  imports: [
    CommonModule,
    PayerRoutingModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class PayerModule { }
