
export interface ISection {
    name: string;
    path: string;
}

export class Section implements ISection {
    name: string;
    path: string;
    subSection?: ISection[];
}
