export class PayerModel {
    PayerId: number;

    // [StringLength(10)]
    Bin: string;

    // [StringLength(20)]
    Pcn: string;

    // [StringLength(20)]
    GroupNumber: string;

    InmarPayerIdentifier: string;

    // [StringLength(100)]
    GroupSettlement: string;

    // [StringLength(100)]
    GroupContracting: string;

    // [StringLength(100)]
    GroupMacAppeals: string;

    // [Required]
    // [StringLength(100)]
    PayerName: string;

    // [Required]
    PayerTypeId: number;

    // not required
    Notes: string;

}
