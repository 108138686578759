export interface ILimitedResultModel<T> {
    Limit: number;
    Offset: number;
    TotalItemCount: number;
    Items: T[];
}

export class LimitedResult<T> implements ILimitedResultModel<T> {
    constructor(public Limit: number, public Offset: number,
        public TotalItemCount: number, public Items: T[]) {
    }
}
