import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { PharmacySearchResultItem } from '../../models';
import { SearchCriteriaModel } from '../../../../models/';
import { MessageService, AuthorizationService } from 'src/app/services';
import { PharmacyService } from '../../services/pharmacy.service';
import { Sort, MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PharmacyEditDialogComponent } from '../pharmacy-edit-dialog/pharmacy-edit-dialog.component';
import { PharmacyUploadDialogComponent } from '../pharmacy-upload-dialog/pharmacy-upload-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';


const DEFAULT_SORT = 'NationalProviderIdentifier';

@Component({
  selector: 'app-pharmacy-search',
  templateUrl: './pharmacy-search.component.html',
  styleUrls: ['./pharmacy-search.component.css']
})
export class PharmacySearchComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;
  defaultPageSize = 30;
  loadingGrid = false;
  pagerSubscription: Subscription;
  addPharmacyFeature = 'UI::PharmacyAdd';
  editPharmacyFeature = 'UI::PharmacyEdit';
  deletePharmacyFeature = 'UI::PharmacyDelete';
  searchFormGroup: FormGroup;
  dataSource: MatTableDataSource<PharmacySearchResultItem>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [];
  searchString: string;
  searchCriteria: SearchCriteriaModel;
  sortOrder = '';
  newSortOrder = DEFAULT_SORT;
  public showFileDialog: boolean=false;

  constructor(private fb: FormBuilder,
    private pharmacyService: PharmacyService,
    private messageService: MessageService,
    private authorizationService: AuthorizationService,
    private editDialog: MatDialog,
    private uploadDialog: MatDialog,
    private deleteDialog: MatDialog) {
  }

  ngOnInit() {
    this.initFormGroup();
    this.setupColumns();
    this.initialSearchLoad();
  }

  ngAfterViewInit() {
    this.paginator.pageSize = this.defaultPageSize;
    this.pagerSubscription = this.paginator.page
      .pipe(
        tap(() => this.loadSearchPage())
      )
      .subscribe();

    this.sort.sortChange.subscribe(data => {
      if (data['active'] !== 'ActionEdit' && data['active'] !== 'ActionDelete') {
        if (data['direction'] === '') {
          this.newSortOrder = '';
        } else {
          this.newSortOrder = `${(data['direction'] === 'desc') ? '-' : ''}${data['active']}`;
        }
        this.loadSearchPage();
      }
    });
  }

  initFormGroup() {
    this.searchFormGroup = this.fb.group({
      searchCriteria: new FormControl(''),
    });
  }

  setupColumns() {
    this.displayedColumns = [
      'PharmacyId',
      'QualifiedProviderId',
      'QualifiedProviderIdQualifier',
      'EffectiveDateBegin',
      'EffectiveDateEnd',
      'NameOrDescription',
      'NationalProviderIdentifier',
      'NcpdpChainCode',
      'NcpdpProviderId',
      'AddressStreet1',
      'AddressStreet2',
      'AddressCity',
      'AddressStateCode',
      'AddressPostalCode',
      'PhoneNumber',
      'DaysOfOperationSundayFlag',
      'DaysOfOperationMondayFlag',
      'DaysOfOperationTuesdayFlag',
      'DaysOfOperationWednesdayFlag',
      'DaysOfOperationThursdayFlag',
      'DaysOfOperationFridayFlag',
      'DaysOfOperationSaturdayFlag',
      'CorporateEntityIdentifier',
      'CorporateGroupOrDivision',
      'CorporateLocationNumber',
      'DataUsageCode',
      'DemoFlag',
      'MarketCategory',
      'PeerGroup',
      'PharmacyType',
      'RetailBanner',
      'HiveClientId'
    ];
    if (this.hasDeletePharmacyFeature()) {
      this.displayedColumns.unshift('ActionDelete');
    }
    if (this.hasEditPharmacyFeature()) {
      this.displayedColumns.unshift('ActionEdit');
    }
  }

  ngOnDestroy() {
    if (this.pagerSubscription) {
      this.pagerSubscription.unsubscribe();
    }
  }

  search() {
    this.paginator.pageIndex = 0;
    this.loadSearchPage();
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = this.defaultPageSize;
    this.initFormGroup();
    this.initialSearchLoad();
  }

  initialSearchLoad() {
    this.sortOrder = DEFAULT_SORT;
    this.newSortOrder = DEFAULT_SORT;
    this.sort.active = DEFAULT_SORT;
    this.sort.direction = 'asc';
    this.sort._stateChanges.next();
    this.searchString = '';
    this.loadSearchPageHelper(this.searchString, this.defaultPageSize, this.paginator.pageIndex, this.newSortOrder);
  }

  loadSearchPage() {
    if (this.newSortOrder !== this.sortOrder) {
      this.paginator.pageIndex = 0;
    }
    this.searchString = this.searchFormGroup.controls['searchCriteria'].value;
    this.loadSearchPageHelper(this.searchString, this.paginator.pageSize,
      (this.paginator.pageIndex * this.paginator.pageSize), this.newSortOrder);
  }

  loadSearchPageHelper(criteria: string, limit: number, offset: number, sortOrder: string) {

    if (this.sortOrder !== sortOrder) {
      this.sortOrder = sortOrder;
    }

    this.loadingGrid = true;
    this.searchCriteria = new SearchCriteriaModel();
    this.searchCriteria.criteria = this.searchString;
    this.pharmacyService.search(this.searchCriteria, limit, offset, sortOrder)
      .subscribe(data => {
        this.dataSource = new MatTableDataSource<PharmacySearchResultItem>(data.Items);
        this.dataSource.sortingDataAccessor = (myDataSource, sortHeaderId) => {
          if (typeof myDataSource[sortHeaderId] === 'string') {
            return myDataSource[sortHeaderId].toLocaleLowerCase();
          } else {
            return myDataSource[sortHeaderId];
          }
        };
        setTimeout(() => {
          this.paginator.length = data.TotalItemCount;
          this.dataSource.sort = this.sort;
        });
        this.loadingGrid = false;
      }, errorResponse => {
        this.messageService.HandleServerError(errorResponse);
        this.loadingGrid = false;
      });
  }
  add() {
    this.openPharmacyEditDialog(null);
  }

  edit(element: PharmacySearchResultItem) {
    this.openPharmacyEditDialog(element);
  }

  openPharmacyEditDialog(element: PharmacySearchResultItem): void {
    const dialogRef = this.editDialog.open(PharmacyEditDialogComponent,
      {
        width: '600px',
        data: { pharmacyId: element ? element.PharmacyId : null }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.search();
      }
    });
  }

 

  openDeleteDialog(element: PharmacySearchResultItem): void {
    const dialogRef = this.deleteDialog.open(ConfirmationDialogComponent,
      {
        width: '600px',
        data: { title: 'Confirm Delete', body: `Are you sure you want to delete Pharmacy ${element.PharmacyId}?` }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.pharmacyService.delete(element.PharmacyId)
          .subscribe(data => {
            this.messageService.ShowDeletedSuccessfullyMessage();
            this.search();
          }, errorResponse => {
            this.messageService.HandleServerError(errorResponse);
          });
      }

    });
  }

  hasAddPharmacyFeature(): boolean {
    return this.authorizationService.hasFeature(this.addPharmacyFeature);
  }

  hasEditPharmacyFeature(): boolean {
    return this.authorizationService.hasFeature(this.editPharmacyFeature);
  }

  hasDeletePharmacyFeature(): boolean {
    return this.authorizationService.hasFeature(this.deletePharmacyFeature);
  }

  upload() {
    this.openPharmacyUploadDialog();
  }

  openPharmacyUploadDialog(): void {
    const dialogRef = this.uploadDialog.open(PharmacyUploadDialogComponent,
    {
      width: '700px'     
       
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      this.initialSearchLoad();
     });

  }

}
