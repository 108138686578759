import { Injectable } from '@angular/core';
import { KeyValuePairModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class StatesService {
  states: KeyValuePairModel[];

  constructor() { }

  public getStateList(): KeyValuePairModel[] {
    if (!this.states) {
      const stateList =
        // tslint:disable-next-line:max-line-length
        '|AL|AK|AZ|AR|CA|CO|CT|DE|DC|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|PR|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY';
      const stateStrings: string[] = stateList.split('|');
      this.states = [];
      stateStrings.forEach(element => {
        this.states.push(new KeyValuePairModel(element, element));
      });
    }
    return this.states;
  }
}
