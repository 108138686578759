import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrismEnvironmentRoutingModule } from './prism-environment-routing.module';
import { MaterialModule } from '../material-module/material-module';
import { ReactiveFormsModule } from '@angular/forms';
import { PrismEnvironmentSearchComponent } from './components/prism-environment-search/prism-environment-search.component';
import { PrismEnvironmentEditDialogComponent } from './components/prism-environment-edit-dialog/prism-environment-edit-dialog.component';

@NgModule({
  declarations: [PrismEnvironmentSearchComponent, PrismEnvironmentEditDialogComponent],
  entryComponents: [
    PrismEnvironmentEditDialogComponent
  ],
  imports: [
    CommonModule,
    PrismEnvironmentRoutingModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})

export class PrismEnvironmentModule { }
