<div id="portal-navbar" style="background-color:#50aed7">
  <div class="portalnav__title"></div>
  <div class="portalnav__content"></div>
  <h3 class='inm-logo--white'></h3>
  <div id="menuBar"> 
    <span id="inmar-logo-text-container" routerLink="">
      <div class="inmar-logo-text">HEALTHCARE</div>
      <div class="inmar-logo-text inmar-logo-text-thin"> MASTERDATA</div>
    </span> 
    <ul id="menuItems">
      <li *ngFor="let section of sectionsObj">
        <a *ngIf="!section.subSection && checkValidLink(section.feature)" routerLinkActive="active" [routerLink]="section.path" [routerLinkActiveOptions]="{ exact: false }">{{ section.name }}</a>
      </li>
    </ul>  
  </div> 
 
</div>
<div name="healthcareTopBar" id="healthcareTopBar"></div>