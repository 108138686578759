import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent {
  private year: number;
  public copyright: string;
  constructor() {
      this.copyright = `© Inmar Inc.` ;
  }

}
