import { Component, Inject, OnInit } from '@angular/core';
import { PrismEnvironmentModel } from '../../models/prism-environment-model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcherDirty, KeyValuePairModel } from 'src/app/models';
import { PrismEnvironmentService } from '../../services/prism-environment.service';
import { AuthorizationService, MessageService } from 'src/app/services';
import { DataUsageCodeService } from 'src/app/services/data-usage-code.service';
import { MAT_DIALOG_DATA, MatDialogRef } from 'node_modules/@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prism-environment-edit-dialog',
  templateUrl: './prism-environment-edit-dialog.component.html',
  styleUrls: ['./prism-environment-edit-dialog.component.css']
})
export class PrismEnvironmentEditDialogComponent implements OnInit {
  changed = false;
  editOnly: boolean;
  formGroup: FormGroup;
  prismEnvironmentModel: PrismEnvironmentModel;
  editMode = false;
  mode = '';
  matcher = new ErrorStateMatcherDirty();
  prismEnvironmentIdentifier: number;

  prismLPARList: KeyValuePairModel[] = [];
  spectrumSQLInstanceList: KeyValuePairModel[] = [];

  constructor(private dialogRef: MatDialogRef<PrismEnvironmentEditDialogComponent>,
    private fb: FormBuilder,
    private prismEnvironmentService: PrismEnvironmentService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) private data: any)  { }

    ngOnInit() {
      // Are we editing or adding
      this.editMode = this.data.prismEnvironmentIdentifier !== null && this.data.prismEnvironmentIdentifier !== undefined ? true : false;
      this.loadPrismLPARs();
      this.loadSpectrumSQLInstances();
      if (this.editMode) {
        this.mode = 'Edit';
        this.prismEnvironmentIdentifier = this.data.prismEnvironmentIdentifier;
        this.prismEnvironmentService.get(this.prismEnvironmentIdentifier)
          .subscribe(data => {
            this.prismEnvironmentModel = data;
            this.loadForm();
          }, errorResponse => {
            this.messageService.HandleServerError(errorResponse);
  
          });
      } else {
        this.mode = 'Add';
        this.prismEnvironmentModel = new PrismEnvironmentModel();
        this.prismEnvironmentModel.PeerGroupIncludeFlag = false;
        this.prismEnvironmentModel.IsGreenlight = false;
        this.loadForm();
      }
    }

    loadForm() {
      this.formGroup = this.fb.group({
        prismEnvironmentIdentifier: [this.prismEnvironmentModel.PrismEnvironmentIdentifier, Validators.required],
        prismEnvironmentName: [this.prismEnvironmentModel.PrismEnvironmentName, Validators.compose([Validators.required, Validators.maxLength(250)])],
        prismEnvironmentNameAnonymous: [this.prismEnvironmentModel.PrismEnvironmentNameAnonymous],
        prismLPAR: [this.prismEnvironmentModel.PrismLPAR, Validators.compose([Validators.required, Validators.maxLength(30)])],
        spectrumSQLInstance: [this.prismEnvironmentModel.SpectrumSQLInstance, Validators.compose([Validators.required, Validators.maxLength(50)])],
        peerGroupName: [this.prismEnvironmentModel.PeerGroupName, Validators.compose([Validators.required, Validators.maxLength(200)])],
        peerGroupIncludeFlag: [this.prismEnvironmentModel.PeerGroupIncludeFlag],
        isGreenlight: [this.prismEnvironmentModel.IsGreenlight],
      });
    }

    loadModelFromForm() {
      const controls = this.formGroup.controls;
      this.prismEnvironmentModel.PrismEnvironmentIdentifier = controls['prismEnvironmentIdentifier'].value;
      this.prismEnvironmentModel.PrismEnvironmentName = controls['prismEnvironmentName'].value;
      this.prismEnvironmentModel.PrismEnvironmentNameAnonymous = controls['prismEnvironmentNameAnonymous'].value;     
      this.prismEnvironmentModel.PrismLPAR = controls['prismLPAR'].value;
      this.prismEnvironmentModel.SpectrumSQLInstance = controls['spectrumSQLInstance'].value;
      this.prismEnvironmentModel.PeerGroupName = controls['peerGroupName'].value;
      this.prismEnvironmentModel.PeerGroupIncludeFlag = controls['peerGroupIncludeFlag'].value;
      this.prismEnvironmentModel.IsGreenlight = controls['isGreenlight'].value;
    }

    save() {
      this.loadModelFromForm();
  
      this.prismEnvironmentService.save(!this.editMode, this.prismEnvironmentModel)
        .subscribe(data => {
          if (this.editMode) {
            this.prismEnvironmentModel = data; // Saved and you can edit some more. The item is not cleared off the screen.
          } else {
            this.prismEnvironmentModel = new PrismEnvironmentModel(); // You can now add another one. we are still in Add mode.
            this.prismEnvironmentModel.IsGreenlight = false;
            this.prismEnvironmentModel.PeerGroupIncludeFlag = false;
          }
          this.changed = true;
          this.loadForm();
          this.messageService.ShowSavedSuccessfullyMessage();
  
        }, errorResponse => {
          this.messageService.HandleServerError(errorResponse);
        });
    }

    dismiss() {
      this.dialogRef.close(this.changed);
    }
  
    loadPrismLPARs() {
      environment.PrismEnvironmentAllowedValues.PrismLPARs.forEach(element => {
        this.prismLPARList.push(new KeyValuePairModel(element, element));
      });
    }

    loadSpectrumSQLInstances() {
      environment.PrismEnvironmentAllowedValues.SpectrumSQLInstances.forEach(element => {
        this.spectrumSQLInstanceList.push(new KeyValuePairModel(element, element));
      });
    }
}
