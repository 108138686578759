<div class="inm-layout-main__content">
  <h3 class="inm-headline--page-title">Corporate Entities</h3>
  <section class='inm-wrapper'>

    <form [formGroup]="searchFormGroup">
      <div class="inm-spaced">
        <mat-form-field>
          <input matInput placeholder="Search criteria" formControlName="searchCriteria">
        </mat-form-field>
        <section class='inm-spaced--compact'>
          <button class="inm-button--action" (click)="search()" [disabled]="searchFormGroup.invalid">Search</button>
          <button class="inm-button" (click)="reset()">Reset</button>
        </section>
        <section class='inm-spaced--compact' *ngIf="hasAddCorporateEntityFeature()">
          <button class="inm-button" (click)="add()">Add</button>
        </section>
      </div>
    </form>

    <div class="inm-spaced" [hidden]="!loadingGrid">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="inm-spaced" [hidden]="loadingGrid || !dataSource">
      <div class="table-container" style="overflow-x:auto;">
        <table mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="ActionEdit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="actionColumn"> Edit </th>
            <td mat-cell *matCellDef="let element" class="actionColumn">
              <button mat-button (click)="edit(element)" style="cursor:pointer">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="ActionDelete">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="actionColumn"> Delete </th>
            <td mat-cell *matCellDef="let element" class="actionColumn">
              <button mat-button style="cursor:pointer" (click)="openDeleteDialog(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="CorporateEntityIdentifier">
            <th mat-header-cell *matHeaderCellDef class="intColumn" mat-sort-header arrowPosition='before'>
              CorporateEntityIdentifier </th>
            <td mat-cell *matCellDef="let element" nowrap class="intColumn"> {{element.CorporateEntityIdentifier}} </td>
          </ng-container>
          <ng-container matColumnDef="CorporateEntityName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CorporateEntityName </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.CorporateEntityName}} </td>
          </ng-container>
          <ng-container matColumnDef="CorporateEntityNameAnonymous">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> CorporateEntityNameAnonymous </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.CorporateEntityNameAnonymous}} </td>
          </ng-container>
          <ng-container matColumnDef="PeerGroupName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PeerGroupName </th>
            <td mat-cell *matCellDef="let element" nowrap> {{element.PeerGroupName}} </td>
          </ng-container>
          <ng-container matColumnDef="PeerGroupIncludeFlag">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PeerGroupIncludeFlag </th>

            <td mat-cell *matCellDef="let element" nowrap>
              <mat-checkbox [disabled]="true" [checked]="element.PeerGroupIncludeFlag"></mat-checkbox>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[30,60,90,10]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </section>
</div>