import { Injectable } from '@angular/core';
import { KeyValuePairModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DataUsageCodeService {
  dataUsageCodes: KeyValuePairModel[];
  constructor() { }

  public getDataUsageCodes(): KeyValuePairModel[] {
    if (!this.dataUsageCodes) {
      this.dataUsageCodes = [];
      this.dataUsageCodes.push(new KeyValuePairModel('R', 'Red'));
      this.dataUsageCodes.push(new KeyValuePairModel('G', 'Green'));
    }
    return this.dataUsageCodes;
  }
}
